import {
  commons,
  kitchenInventoryManagement,
  physicalCountSchedule,
  productionManagement,
  purchaseOrderConciliation,
  purchaseOrderCreation,
  purchaseOrderLine,
  purchaseOrderModule,
  supplyingManagement,
  transferModule,
  transferOrderDetails,
  transferOrderModule,
  vendor,
} from "app/i18n/types";
import { InventoryModule } from "utils/module";

export enum LocationType {
  CP = "PCENTER",
  KITCHEN = "KITCHEN",
}

export const kitchenInventorySubmodules: Array<InventoryModule> = [
  {
    moduleId: "kitchenInventory",
    moduleName: kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE,
    description: kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION,
    link: "/physical-count/:kitchenId",
  },
  {
    moduleId: "kitchenPurchaseOrdersReception",
    moduleName: kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE,
    description: kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION,
    link: "/receipt-order/:kitchenId",
    submodules: [
      {
        moduleId: "",
        moduleName: purchaseOrderModule.RECEIPT,
        link: "/receipt-order/:kitchenId/purchase/:orderId/:origin",
      },
      {
        moduleId: "",
        moduleName: transferModule.RECEIPT,
        link: "/receipt-order/:kitchenId/transfer/:orderId/:origin",
      },
    ],
  },
  {
    moduleId: "kitchenInventoryAdjustment",
    moduleName: kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE,
    description:
      kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION,
    link: "/daily-adjustments/:kitchenId",
  },
  {
    moduleId: "productionRecords",
    moduleName: kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE,
    description: kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION,
    link: "/productions/:kitchenId",
  },
  {
    moduleId: "kitchenLoadTransfers",
    moduleName: kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE,
    description: kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION,
    link: "/transfers/:kitchenId",
  },
  {
    moduleId: "kitchenAuthorizeTransfers",
    moduleName: kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE,
    description: kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION,
    link: "/transfers/:kitchenId/review-packed",
  },
  {
    moduleId: "kitchenAdjustmtnsAuthorization",
    moduleName: kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE,
    description: kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION,
    link: "/daily-adjustments/:kitchenId/authorize",
  },
];

export const cpInventorySubmodules: Array<InventoryModule> = [
  {
    moduleId: "kitchenInventory",
    moduleName: kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE,
    description: kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION,
    link: "/physical-count/:kitchenId",
  },
  {
    moduleId: "kitchenPurchaseOrdersReception",
    moduleName: kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE,
    description: kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION,
    link: "/receipt-order/:kitchenId",
  },
  {
    moduleId: "productionRecords",
    moduleName: kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE,
    description: kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION,
    link: "/productions/:kitchenId",
  },
  {
    moduleId: "kitchenInventoryAdjustment",
    moduleName: kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE,
    description:
      kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION,
    link: "/daily-adjustments/:kitchenId",
  },
  {
    moduleId: "kitchenAdjustmtnsAuthorization",
    moduleName: kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE,
    description: kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION,
    link: "/daily-adjustments/:kitchenId/authorize",
  },
  {
    moduleId: "kitchenLoadTransfers",
    moduleName: kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE,
    description: kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION,
    link: "/transfers/:kitchenId",
  },
  {
    moduleId: "kitchenPacktransfers",
    moduleName: kitchenInventoryManagement.TRANSFER_PICKING_PACKING_TITLE,
    description:
      kitchenInventoryManagement.TRANSFER_PICKING_PACKING_DESCRIPTION,
    link: "/transfers/:kitchenId/packing",
  },
  {
    moduleId: "kitchenAuthorizeTransfers",
    moduleName: kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE,
    description: kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION,
    link: "/transfers/:kitchenId/review-packed",
  },
  {
    moduleId: "supplyingCP",
    moduleName: supplyingManagement.MODULE_TITLE,
    description: supplyingManagement.MODULE_DESCRIPTION,
    link: "/supplying/:kitchenId",
  },
  {
    moduleId: "kitchenProductionSupplyReception",
    moduleName: kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_TITLE,
    description:
      kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION,
    link: "/productions/supply-reception/:kitchenId",
  },
  {
    moduleId: "productionLiberation",
    moduleName: kitchenInventoryManagement.PRODUCTION_LIBERATION_TITLE,
    description: kitchenInventoryManagement.PRODUCTION_LIBERATION_DESCRIPTION,
    link: "/productions/liberation/:kitchenId",
  },
];

export const inventoryAdminSubmodules: Array<InventoryModule> = [
  {
    moduleId: "inventoryReview",
    moduleName: kitchenInventoryManagement.INVENTORYREVIEW_MODULE,
    description: kitchenInventoryManagement.INVENTORYREVIEW_MODULE_DESCRIPTION,
    link: "/theorical-inventory",
  },
  {
    moduleId: "theoreticalInventorycomparison",
    moduleName:
      kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE,
    description:
      kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION,
    link: "/theorical-inventory/comparison",
  },
  {
    moduleId: "countDownload",
    moduleName: kitchenInventoryManagement.COUNTDOWNLOAD_MODULE,
    description: kitchenInventoryManagement.COUNTDOWNLOAD_MODULE_DESCRIPTION,
    link: "/physical-count/consolidated",
  },
  {
    moduleId: "dailyMovs",
    moduleName: kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE,
    description: kitchenInventoryManagement.DAILYMOVS_MODULE_DESCRIPTION,
    link: "/daily-adjustments/report",
  },
  {
    moduleId: "suppliesManagement",
    moduleName: kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE,
    description:
      kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE_DESCRIPTION,
    link: "/supplies-management",
  },
  {
    moduleId: "massiveAdjusts",
    moduleName: kitchenInventoryManagement.MASSIVEADJUSTS_MODULE,
    description: kitchenInventoryManagement.MASSIVEADJUSTS_MODULE_DESCRIPTION,
    link: "/adjustments",
  },
  {
    moduleId: "schedulePhysicalCounting",
    moduleName: physicalCountSchedule.HEADER_PAGE_TITLE,
    description: physicalCountSchedule.HEADER_PAGE_SUBTITLE,
    link: "/physical-count/schedule",
  },
  {
    moduleId: "singleAdjusts",
    moduleName: kitchenInventoryManagement.SINGLEADJUSTS_MODULE,
    description: kitchenInventoryManagement.SINGLEADJUSTS_MODULE_DESCRIPTION,
    link: "/adjustments/manual",
  },
  {
    moduleId: "authorizationRecount",
    moduleName: kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE,
    description:
      kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION,
    link: "/adjustments/re-count",
  },
  {
    moduleId: "productionLabelLog",
    moduleName: productionManagement.LOG_TITLE,
    description: productionManagement.LOG_DESCRIPTION,
    link: "/productions/log",
  },
  {
    moduleId: "transferOrders",
    moduleName: transferOrderModule.TITLE,
    description: transferOrderModule.DESCRIPTION,
    link: "/transfer-order",
    submodules: [
      {
        moduleId: "",
        moduleName: transferOrderDetails.TITLE,
        link: "/transfer-order/:id",
      },
    ],
  },
  {
    moduleId: "bcIntegration",
    moduleName: kitchenInventoryManagement.BC_INTEGRATION_MODULE,
    description: kitchenInventoryManagement.BC_INTEGRATION_MODULE_DESCRIPTION,
    link: "/bc-integration",
  },
];

export const resourcesSubmodules: Array<InventoryModule> = [
  {
    moduleId: "routesCurrent",
    moduleName: commons.ROUTES,
    description: "",
    link: "/routes/current",
  },
];

export const supplyAdminSubmodules: Array<InventoryModule> = [
  {
    moduleId: "createPurchaseOrder",
    moduleName: purchaseOrderCreation.TITLE,
    description: purchaseOrderCreation.DESCRIPTION,
    link: "/purchase-orders/create",
  },
  {
    moduleId: "purchaseOrder",
    moduleName: purchaseOrderModule.TITLE,
    description: purchaseOrderModule.DESCRIPTION,
    link: "/purchase-orders",
    submodules: [
      {
        moduleId: "",
        moduleName: purchaseOrderLine.TITLE,
        link: "/purchase-orders/:kitchenId/:orderId/:origin",
      },
    ],
  },
  {
    moduleId: "purchaseOrderConciliation",
    moduleName: purchaseOrderConciliation.TITLE,
    description: purchaseOrderConciliation.DESCRIPTION,
    link: "/purchase-orders/conciliation",
    submodules: [
      {
        moduleId: "",
        moduleName: purchaseOrderLine.TITLE,
        link: "/purchase-orders/conciliation/:kitchenId/:orderId",
      },
    ],
  },
  {
    moduleId: "vendorSupply",
    moduleName: vendor.VENDOR_SUPPLY_MODULE_TITLE,
    description: vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION,
    link: "/vendor",
    submodules: [
      {
        moduleId: "",
        moduleName: vendor.VENDOR_SUPPLIES_TITLE,
        link: "/vendor/:vendorId/supplies",
      },
      {
        moduleId: "",
        moduleName: vendor.SUPPLY_VENDORS_TITLE,
        link: "/vendor/sku/:sku/city/:city",
      },
    ],
  },
  {
    moduleId: "invoices",
    moduleName: purchaseOrderModule.INVOICE_TITLE,
    description: purchaseOrderModule.INVOICE_DESCRIPTION,
    link: "/invoices",
  },
];

export const moduleConfig: Array<InventoryModule> = [
  {
    moduleId: "kitchen-inventory-management",
    moduleName: kitchenInventoryManagement.INVENTORY_MODULE,
    description: kitchenInventoryManagement.INVENTORY_MODULE_DESCRIPTION,
    submodules: kitchenInventorySubmodules,
    locationType: LocationType.KITCHEN,
  },
  {
    moduleId: "production-center-inventory-management",
    moduleName: kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_CP,
    description:
      kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION_CP,
    submodules: cpInventorySubmodules,
    locationType: LocationType.CP,
  },
  {
    moduleId: "inventory-admin",
    moduleName: kitchenInventoryManagement.INVENTORY_ADMIN_MODULE,
    description: kitchenInventoryManagement.INVENTORY_ADMIN_MODULE_DESCRIPTION,
    submodules: inventoryAdminSubmodules,
  },
  {
    moduleId: "supply-admin",
    moduleName: kitchenInventoryManagement.SUPPLY_ADMIN_MODULE,
    submodules: supplyAdminSubmodules,
  },
  {
    moduleId: "inventory-resources",
    moduleName: commons.RESOURCES,
    submodules: resourcesSubmodules,
  },
];
