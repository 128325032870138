import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Grid, Paper, Typography } from "@mui/material";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import PurchaseOrderStatusSelector from "app/components/PurchaseOrder/Status/Selector";
import PurchaseTable from "app/components/PurchaseRecord/PurchaseTable";
import VendorSelector from "app/components/Vendor/Selector";
import {
  commons,
  purchaseOrderConciliation,
  purchaseOrderModule,
} from "app/i18n/types";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { getPurchaseOrdersForConciliation } from "core/purchaseOrders/repositories/http/purchase";
import { Vendor } from "core/vendors/entities/Vendor";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { compareSearchText, getLocationAndType } from "utils/general";
import { PageBox } from "utils/generalUI";

interface Props {}

const PurchaseOrderConciliation: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [orders, setOrders] = useState<Purchase[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Purchase[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const getData = () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedKitchen,
    });
    if (!params) return;
    setLoading(true);
    setSelectedVendor(undefined);
    setSelectedStatus(undefined);
    setOrders([]);
    getPurchaseOrdersForConciliation(params.type, params.location)
      .then((response) => {
        setOrders(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedKitchen,
    });
    if (!params) return;
    setLoading(true);
    setOrders([]);
    getPurchaseOrdersForConciliation(params.type, params.location)
      .then((response) => {
        setOrders(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [selectedKitchen, selectedCountry]);

  const statusValidate = (order: Purchase): boolean => {
    if (!selectedStatus) return true;
    return order.status === selectedStatus;
  };

  const vendorValidate = (order: Purchase): boolean => {
    const vendorId = selectedVendor?.id ? selectedVendor.id.toString() : "";
    if (!vendorId) return true;
    return order.vendorId === vendorId;
  };

  const searchValidate = (order: Purchase, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(order.orderId, toSearch) ||
      compareSearchText(order.code, toSearch) ||
      compareSearchText(order.invoiceNo, toSearch)
    );
  };

  useEffect(() => {
    setFilteredOrders(
      orders.filter((order) => {
        return (
          searchValidate(order, search) &&
          statusValidate(order) &&
          vendorValidate(order)
        );
      })
    );
  }, [orders, search, selectedStatus, selectedVendor]);

  const getCSV = () => {
    if (orders.length) {
      const data = orders.map((purchase) => {
        return {
          orderId: purchase.orderId,
          kitchen: purchase.kitchenId,
          proveedor: purchase.vendorName,
          date: purchase.dateDocument,
          receipt: purchase.receiptDate,
          invoiceNo: purchase.invoiceNo,
          code: purchase.code,
          total: formatPrice(purchase.totalNet, localeData[purchase.country]),
          status: purchase.status,
        };
      });
      downloadTextAsCsv(
        json2Csv(data),
        `${t(purchaseOrderConciliation.TITLE)} ${new Date().getTime()}.csv`
      );
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderConciliation.TITLE)}
        subtitle={t(purchaseOrderConciliation.DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: update,
          },
          {
            children: t(commons.EXPORT),
            variant: "contained",
            onClick: getCSV,
            disabled: !orders.length,
            startIcon: <FileDownloadOutlinedIcon />,
          },
        ]}
      />

      <SearchOnTopBar onSearch={setSearch} />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(purchaseOrderModule.FILTER_ORDERS)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <KitchenSelector
                  onCountryChange={(country) =>
                    setSelectedCountry(country ?? "")
                  }
                  onKitchenChange={(kitchen) =>
                    setSelectedKitchen(kitchen?.kitchenId ?? "")
                  }
                  hideCitySelector
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <VendorSelector
                  country={selectedCountry ?? ""}
                  selectedVendor={selectedVendor}
                  setSelectedVender={setSelectedVendor}
                  disabled={!selectedCountry}
                  isShortTitle
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <PurchaseOrderStatusSelector
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                  disabled={!selectedCountry}
                  isCorrected
                  isValidated
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <PurchaseTable
        items={filteredOrders}
        loading={loading}
        isConciliation
        canPagination
        onUpdate={update}
      />
    </PageBox>
  );
};

export default PurchaseOrderConciliation;
