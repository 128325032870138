import {
  EventReasonType,
  Locales,
  adjustmentType,
  adjustmentsModule,
  bcIntegrations,
  bulkLoadAction,
  bulkLoadError,
  bulkLoadModule,
  commentArea,
  commons,
  countingTypeSelector,
  dailyAdjustmentsModule,
  dailyAdjustmentsReport,
  errorCode,
  inventoryGroups,
  inventoryMovementType,
  kitchenInventoryManagement,
  massiveTransfer,
  measureUnits,
  missingReason,
  organoleptics,
  physicalCountModule,
  physicalCountSchedule,
  productionDialog,
  productionLiberation,
  productionLogReason,
  productionManagement,
  productionPreparation,
  productionScannedError,
  productionSchedule,
  purchaseOrderConciliation,
  purchaseOrderCreation,
  purchaseOrderLine,
  purchaseOrderModule,
  receiptType,
  status,
  suppliesManagerModule,
  supplyReception,
  supplyingManagement,
  takePhotoLocale,
  theoricalInventoryModule,
  transferModule,
  transferOrderDetails,
  transferOrderModule,
  transferOrdersPackedReviewTable,
  transferPackingDialog,
  vendor,
  vendorMeasureUnit,
} from "./types";

export const ptLocale: Locales = {
  [status.IN_PROCESS]: "EM PROCESSO",
  [status.ARCHIVED]: "ARQUIVADO",
  [status.RELEASED]: "LIBERADO",
  [status.PACKED]: "EMBALADO",
  [status.IN_TRANSIT]: "EM TRÂNSITO",
  [status.RECEIVED]: "RECEBIDO",
  [status.RETURNED]: "DEVOLVIDO",
  [status.PARTIAL_RECEIVED]: "PARCIALMENTE RECEBIDO",
  [status.ALL]: "TODOS",

  [inventoryGroups.BEER]: "CERVEJA",
  [inventoryGroups.ADJUSTABLE_PACKAGING]: "EMBALAGENS AJUSTÁVEIS",
  [inventoryGroups.ALCOHOLIC_DRINK]: "BEBIDA ALCOÓLICA",
  [inventoryGroups.BAKERY]: "PADARIA",
  [inventoryGroups.CIGARETTES]: "CIGARROS",
  [inventoryGroups.COMBO]: "COMBO",
  [inventoryGroups.DAIRY]: "LATICÍNIO",
  [inventoryGroups.DRINK]: "BEBIDAS",
  [inventoryGroups.FINISHED_PRODUCT]: "PRODTERMIN",
  [inventoryGroups.FRUITS]: "FRUTAS",
  [inventoryGroups.GROCERY]: "MERCEARIA",
  [inventoryGroups.MAINTENANCE]: "MANUTENÇÃO",
  [inventoryGroups.MEAT]: "CARNES",
  [inventoryGroups.PACKAGING]: "EMBALAGENS",
  [inventoryGroups.PRODUCTION]: "PRODUÇÃO",
  [inventoryGroups.SUPPLY]: "INSUMO",
  [inventoryGroups.UTENSILS]: "UTENSÍLIOS",

  [commons.DAILY_INDIVIDUAL]: "Diário individual",
  [commons.DAILY_MASSIVE]: "Diário em massa",
  [commons.DAILY]: "Diario",
  [commons.WEEKLY]: "Semanalmente",
  [commons.MONTHLY]: "Mensalmente",
  [commons.RE_COUNT]: "Recontagem",
  [commons.OTHER]: "Outro",
  [commons.OTHER_INDIVIDUAL]: "Outro individual",
  [commons.OTHER_MASSIVE]: "Outro em massa",
  [commons.LOADING]: "Cargando",
  [commons.ACCEPT]: "Aceptar",
  [commons.INVENTORY]: "Registro de estoque",
  [commons.PURCHASE_ORDER]: "Recepción órden de compra",
  [commons.FILTERS]: "Filtros",
  [commons.RETURN]: "Retorna",
  [commons.RETURN2]: "Retorna",
  [commons.FINISH]: "Finalizar",
  [commons.START]: "Começar",
  [commons.FINISHED]: "Finalizado",
  [commons.NAME]: "Nome",
  [commons.MEASURE_UNIT]: "Medida",
  [commons.SKU]: "SKU",
  [commons.STATUS]: "Status",
  [commons.GROUP]: "Grupo",
  [commons.DOES_NOT_APPLY]: "Não aplica",
  [commons.DETAILS]: "Mais detalhes",
  [commons.SHOW_DETAILS]: "Veja detalhes",
  [commons.CATEGORIES]: "Categorías",
  [commons.CATEGORY]: "Categoría",
  [commons.CONTINUE]: "Continuar",
  [commons.RECEIVE]: "Receber",
  [commons.PROGRESS]: "Progreso",
  [commons.DELIVERY_DATE]: "Data de entrega",
  [commons.ORDER_DATE]: "Data do pedido",
  [commons.SUPPLIER]: "Fornecedor",
  [commons.ORDER_ID]: "Id do pedido",
  [commons.RECEIPT_ID]: "ID de la recepção",
  [commons.PRODUCT_NAME]: "Nome de producto",
  [commons.REQUESTED]: "Solicitado",
  [commons.RECEIVED]: "Recebido",
  [commons.UNIT]: "Unidade",
  [commons.UNITS]: "Unidades",
  [commons.UNIT_PRICE]: "Preço unitário",
  [commons.TOTAL_PRICE]: "Preço total",
  [commons.ACTIONS]: "Ações",
  [commons.ACTION]: "Ação",
  [commons.INIT_DATE]: "Data de início",
  [commons.FINAL_DATE]: "Data final",
  [commons.SEARCH]: "Procurar",
  [commons.EXPORT]: "Exportar",
  [commons.CANCEL]: "Cancelar",
  [commons.DELETE]: "Retirar",
  [commons.MARK_RECEIVED]: "Pedido recebido",
  [commons.SAVE]: "Guardar",
  [commons.BACK]: "Voltar",
  [commons.COUNTRIES]: "Paises",
  [commons.TYPE]: "Modelo",
  [commons.SELECT_FILE]: "Selecionar arquivo",
  [commons.SELECT_TEMPLATE]: "Você deve selecionar o modelo",
  [commons.SELECT_MOVE_FILE]: "Selecione ou arraste o arquivo",
  [commons.SELECT_MOVE_TEMPLATE]: "Selecione ou arraste o modelo",
  [commons.SELECTED_FILE]: "Arquivo selecionado",
  [commons.UPLOAD_FILE]: "Carregar arquivo",
  [commons.UPLOAD_EVIDENCE]: "Carregar evidência",
  [commons.MAKE_ADJUSTMENT]: "Realizar Ajuste",
  [commons.KITCHENS]: "Cozinhas",
  [commons.KITCHEN]: "Cozinha",
  [commons.QUANTITY]: "Quantia",
  [commons.LAST_MODIFIED]: "Última modificação",
  [commons.PERSON_IN_CHARGE]: "Encarregado",
  [commons.REASON]: "Razão",
  [commons.RETRY]: "Tentar novamente",
  [commons.DATETIME]: "Data / Hora",
  [commons.AUTHORIZE]: "Autorizar",
  [commons.EMPTY_RESULT]: "Nenhum resultado encontrado, tente novamente.",
  [commons.BACK_BUTTON]: "Voltar",
  [commons.FIND_INPUT]: "Localizador de entrada",
  [commons.FIND_LOCATION_TITLE]: "Localizador de Cozinha",
  [commons.FIND_LOCATION_INPUT]: "Pesquisar Cozinha",
  [commons.CONFIRM]: "Confirme",
  [commons.CONFIRMATION]: "Confirm",
  [commons.VALIDATE]: "VALIDAR",
  [commons.ENTER_QUANTITY]: "Inserir quantidade",
  [commons.SHOW_ALL]: "MOSTRE TUDO",
  [commons.ALL]: "Tudo",
  [commons.NEW_PRODUCTION]: "NOVA PRODUÇÃO",
  [commons.PRODUCTION_QUANTITY]: "Quantidade a Produzir",
  [commons.INGREDIENT_NAME]: "NOME DO INGREDIENTE",
  [commons.REQUIRED_QUANTITY]: "QUANTIDADE REQUERIDA",
  [commons.EXISTING_QUANTITY]: "QUANTIDADE EXISTENTE",
  [commons.USED_QUANTITY]: "VALOR USADO",
  [commons.FINAL_QUANTITY]: "Quantidade final",
  [commons.EXISTENCE]: "Existência",
  [commons.ENTER]: "INGESTÃO",
  [commons.UPDATE]: "Atualizar",
  [commons.SELECT_COUNTRY]: "Pais",
  [commons.SELECT_CITY]: "Cidade",
  [commons.SELECT_LOCATION]: "Localização",
  [commons.SELECT_LOCATIONS]: "Selecione a localização",
  [commons.INGREDIENTS]: "Ingredientes",
  [commons.SELECTED]: "Selecionado",
  [commons.SCHEDULE_DATE]: "Data agendada",
  [commons.HOUR]: "Hora",
  [commons.PRODUCTION]: "Produção",

  [commons.TABLE_EMPTY]: "Não há registros para mostrar",
  [commons.PARTIAL]: "Parcial",
  [commons.PARTIAL_RECEIVED]: "Parcial",
  [commons.PENDING]: "Pendente",
  [commons.COMPLETE]: "Completo",
  [commons.PARTIAL_PLURAL]: "Parciais",
  [commons.PENDING_PLURAL]: "Pendente",
  [commons.COMPLETE_PLURAL]: "Completo",
  [commons.REMAINING]: "Restante",
  [commons.SENT]: "Enviado",
  [commons.SEND_DATE]: "Data de envio",
  [commons.ERROR_TITLE]: "A página não existe ou você não tem acesso...",
  [commons.ERROR_SUBTITLE]: "Entre em contato com o administrador.",
  [commons.SUPPLIES]: "Suprimentos",
  [commons.SUPPLY]: "Suprimento",
  [commons.ACCURACY]: "Accuracy",
  [commons.FIND_SUPPLY_LABEL]: "Entrada de pesquisa",
  [commons.SHOWING]: "Showing",
  [commons.SELECT_OPTION]: "Seleciona a informação que desejas consultar",
  [commons.SELECT_COUNTRY_CITY_KITCHEN]:
    "Utiliza os filtros para selecionar a informação do país, cidade ou cozinha que desejas consultar.",
  [commons.DATE]: "Data",
  [commons.REPORTED_AMOUNT]: "Valor informado",
  [commons.SEND_TO_VALIDATION]: "Solicitar validação",
  [commons.TO_AUTHORIZE]: "Para ser autorizado",
  [commons.REPORT]: "Relatório",
  [commons.FOR_VALIDATE]: "A ser validado",
  [commons.CLOSE]: "Fechar",
  [commons.SAVE_CHANGES]: "Salvar alterações",
  [commons.WEEK]: "Semana",
  [commons.AMOUNT_SET_ASIDE]: "Valor reservado",
  [commons.TRANSFER_DATE]: "Data de transferência",
  [commons.PROGRAMMED_KITCHENS]: "Cozinhas programadas",
  [commons.TOTAL_SUPPLIES]: "Total de suprimentos",
  [commons.PROGRAMMED_BY]: "Agendado por",
  [commons.PACKING_PROGRESS]: "Progresso da embalagem",
  [commons.PACKING_PROGRESS_SUPPLIES]:
    "{{embalagem}} de {{total}} suprimentos ({{percentage}})",
  [commons.PACKING_PROGRESS_KITCHENS]:
    "{{embalagem}} de {{total}} cozinhas ({{percentage}})",
  [commons.SCANNED]: "Digitalizado",
  [commons.SCANNED_SUCCESSFULLY]: "Digitalizado corretamente",
  [commons.SCANNING_ERROR]: "Erro na varredura",
  [commons.SEARCH_RESULT]: "Resultado da pesquisa",
  [commons.RESULTS_FOR]: "Resultados para",
  [commons.READING_BARCODE_SCANNER]: "Lendo o scanner de código de barras",
  [commons.PRODUCTION_ALREADY_ENTERED]: "Esta produção já foi inserida",
  [commons.ADD]: "Adicionar",
  [commons.DOCUMENT_CODE]: "Código do documento",
  [commons.PRINT_LABELS]: "Imprimir etiqueta",
  [commons.PACK]: "Pacote",
  [commons.PRINT]: "Imprimir",
  [commons.SUPPLIES_FILTER]: "Filtrar suprimentos",
  [commons.VIEW_MODES]: "Modos de visualização",
  [commons.FOR_SUPPLY]: "Para entrada",
  [commons.FOR_KITCHEN]: "Para cozinha",
  [commons.EMAIL_LABEL]: "Correio eletrônico",
  [commons.ORDER_DETAILS]: "Detalhes do pedido",
  [commons.ENTER_INVOICE_OR_REMISSION_NO]:
    "Insira o número da fatura/remissão.",
  [commons.DECLINE]: "Rejeitar",
  [commons.FILE_TOO_BIG]: "Arquivo muito grande (máx. {{size}}MB)",
  [commons.ORDER_RECEIPT]: "Receber pedido",
  [commons.VALIDATED]: "Validado",
  [commons.WITHOUT_INVOICE]: "Sem fatura",
  [commons.ERROR_IN_PRICE]: "Erro no preço",
  [commons.ERROR_IN_QUANTITY]: "Erro na quantidade",
  [commons.ERROR_IN_PRICE_AND_QUANTITY]: "Erro na quantidade e preço",
  [commons.ACCOUNTED]: "Contado",
  [commons.PRE_VALIDATED]: "Pré-validado",
  [commons.RECEIPT]: "Recibo",
  [commons.CHANGE_STATUS]: "Alterar status",
  [commons.SELECT_A_NEW_STATUS]: "Selecione o novo status para este pedido",
  [commons.RECIVED_QUANTITY]: "Quantidade recebida",
  [commons.CORRECTED]: "Corrigido",
  [commons.VIEW_PDF]: "Ver PDF",
  [commons.PDF]: "PDF",
  [commons.MODIFY]: "Modificar",
  [commons.COMMENT]: "Comentário",
  [commons.PRODUCTIONS]: "Produções",
  [commons.MONDAY]: "Segunda-feira",
  [commons.TUESDAY]: "Terça-feira",
  [commons.WEDNESDAY]: "Quarta-feira",
  [commons.THURSDAY]: "Quinta-feira",
  [commons.FRIDAY]: "Sexta-feira",
  [commons.SATURDAY]: "Sábado",
  [commons.SUNDAY]: "Domingo",
  [commons.TODAY]: "Hoje",
  [commons.TO_THE]: "al",
  [commons.JANUARY]: "Janeiro",
  [commons.FEBRUARY]: "fevereiro",
  [commons.MARCH]: "Março",
  [commons.APRIL]: "abril",
  [commons.MAY]: "maio",
  [commons.JUNE]: "junho",
  [commons.JULY]: "Julho",
  [commons.AUGUST]: "Agosto",
  [commons.SEPTEMBER]: "Setembro",
  [commons.OCTOBER]: "Outubro",
  [commons.NOVEMBER]: "Novembro",
  [commons.DECEMBER]: "Dezembro",
  [commons.IMPORT]: "Importar",
  [commons.MASSIVE]: "Massivo",
  [commons.INDIVIDUAL]: "individual",
  [commons.TIME]: "Tempo",
  [commons.NO_SEARCH_RESULTS]:
    "Nenhuma correspondência encontrada para: {{search}}",
  [commons.ID]: "ID",
  [commons.PREPARE]: "Alistar",
  [commons.QUANTITY_ERROR]: "A quantidade deve ser maior que 0",
  [commons.CODE]: "Código",
  [commons.ERROR]: "Erro",
  [commons.ACTIVATE]: "Ativar",
  [commons.INACTIVATE]: "Desativar",
  [commons.EXIT]: "Sair",
  [commons.ARCHIVE]: "Arquivo",
  [commons.RESOURCES]: "Recursos",
  [commons.ROUTES]: "Rotas",
  [commons.COUNTRY]: "País",
  [commons.CITY]: "Cidade",
  [commons.FILTER_BY_CITY]: "Filtrar por cidade",
  [commons.LOCATION]: "Localização",
  [commons.LABELS]: "Rótulos",
  [commons.VIEW_LABELS]: "Ver rótulos",
  [commons.RETURNED]: "Retorno",
  [commons.ADDRESS]: "Direção",
  [commons.PHONE]: "Telefone",
  [commons.YES]: "Sim",
  [commons.NO]: "Não",
  [commons.REQUIRED_FIELD]: "Valor obrigatório",
  [commons.SUCCESSFUL_PROCESS]: "Processo concluído com sucesso.",
  [commons.FAILED_PROCESS]: "Ocorreu um erro de processo.",
  [commons.SKU_TYPE_CITY_ALREADY_EXIST]:
    "A categorização para este insumo já existe nesta cidade",
  [commons.CONTENT_IS_EMPTY]: "O conteúdo está vazio",
  [commons.SEND]: "Enviar",
  [commons.BETTER]: "Melhor",
  [commons.BUYER]: "Comprador",
  [commons.DOWNLOAD_PDFS]: "Baixar PDFs",
  [commons.THE_PROCESS_CONTINUES]: "Continuar o processo",
  [commons.A_SIMILAR_RECORD_ALREADY_EXIST]: "Já existe um registro semelhante",
  [commons.BULK_LOAD]: "Carga em massa",
  [commons.TEMPLATE_VIEW]: "Ver/Baixar modelo",
  [commons.ERROR_LINE]: "Erro na linha {{index}}: {{error}}",
  [commons.EMPTY_ERROR]: "Existem campos vazios",
  [commons.RESET]: "Redefinir",
  [commons.SUCCESSFUL]: "Bem sucedido",
  [commons.FAILED]: "Falha",
  [commons.RESULT]: "Resultado",
  [commons.ONLY_FILE_TYPE]: "Apenas arquivos do tipo: {{type}}",
  [commons.DOCUMENT]: "Documento",
  [commons.VENDOR_INFO]: "Informação do fornecedor",
  [commons.RECORD]: "Registro",
  [commons.OF]: "de",
  [commons.SEEKER]: "Pesquisador",
  [commons.FINISHED_PRODUCTS]: "Produtos acabados",
  [commons.AUTOGENERATE]: "Gera automaticamente",
  [commons.LINK]: "Associado",
  [commons.SUBTOTAL]: "Subtotal",
  [commons.INVOICE]: "Fatura",
  [commons.WITH_INVOICE]: "Com fatura",
  [commons.ITEMS]: "Items",
  [commons.UNLINK]: "Desvincular",
  [commons.VENDOR]: "Fornecedor",

  [measureUnits.UN]: "Unidade",
  [measureUnits.G]: "Gramas",
  [measureUnits.ML]: "Mililitros",
  [measureUnits.GR]: "Gramas",
  [measureUnits.KG]: "Quilogramas",

  [adjustmentType.LOSS]: "Desperdicio",
  [adjustmentType.LOSSES]: "Desperdicios",
  [adjustmentType.INTERNAL_CONSUMPTION]: "Consumo interno",
  [adjustmentType.FINISHED_PRODUCT]: "Produto acabado",
  [adjustmentType.PRODUCTION_REJECTED]: "Produção rejeitada",
  [adjustmentType.EVENT]: "Evento",
  [adjustmentType.EVENTS]: "Eventos",
  [adjustmentType.DISCARDED]: "Perda",
  [adjustmentType.DISCARDEDS]: "Perdas",
  [adjustmentType.SALE]: "Venda",
  [adjustmentType.PRODUCTION]: "Produção",
  [adjustmentType.PURCHASE_ORDER]: "Pedido de compra",
  [adjustmentType.COUNTING]: "Conteo",
  [adjustmentType.INITIAL]: "Inicial",
  [adjustmentType.ADJUSTMENT]: "Ajustes",
  [adjustmentType.MANUAL_ADJUSTMENT]: "Ajuste Manual",
  [adjustmentType.VALIDATION_ADJUSTMENT]: "Ajuste de validação",
  [adjustmentType.COUNTING_ADJUSTMENT]: "Ajuste de contagem",
  [adjustmentType.SALE_CANCELLATION]: "Venda cancelada",
  [adjustmentType.UPDATE_PURCHASE_ORDER_LINE]: "Ajuste do pedido de compra",
  [adjustmentType.DAILY_COUNTING_ADJUSTMENT]: "Ajuste da contagem diária",
  [adjustmentType.TRANSFER]: "Ordem de transferência",
  [adjustmentType.POSITIVE]: "Positivo",
  [adjustmentType.NEGATIVE]: "Negativo",

  [inventoryMovementType.EGRESS]: "Saída",
  [inventoryMovementType.ENTRY]: "Inventário",

  [EventReasonType.DAMAGED]: "Produto danificado",
  [EventReasonType.EXPIRED]: "Produto vencido",
  [EventReasonType.OBSOLETE]: "Produto obsoleto",
  [EventReasonType.TRAINING]: "Treinamento / Capacitação",
  [EventReasonType.MARKETING]: "Processo de Comercialização",
  [EventReasonType.QUALITY_TESTS]: "Testes de Qualidade",

  [receiptType.ALL]: "Todas",
  [receiptType.PURCHASE_ORDER]: "Ordem de Compra",
  [receiptType.INBOUND_TRANSFER]: "Transferir",
  [receiptType.PENDING]: "Pendentes",
  [receiptType.RECEIVED]: "Recebidas",
  [receiptType.DETAIL_TITLE]: "Detalhe da recepção",

  [physicalCountModule.TITLE]: "Registro de estoque",
  [physicalCountModule.SUBTITLE]:
    "Digite a quantidade de itens disponíveis no estoque",
  [physicalCountModule.HISTORY_SUBTITLE]:
    "Selecione a contagem física que deseja continuar ou crie uma nova.",
  [physicalCountModule.CONFIRMATION_FINISHED_TITLE]: "Desviación Detectada",
  [physicalCountModule.CONFIRMATION_MODAL_DESCRIPTION]:
    "La cantidad ingresada tiene una desviación muy alta contra el inventario teórico. Por favor valida el conteo o confirma para continuar.",
  [physicalCountModule.COUNTING_FINISHED_TITLE]: "Conteo físico finalizado.",
  [physicalCountModule.COUNTING_FINISHED_SUBTITLE]:
    "El conteo físico ha sido finalizado de manera exitosa.",
  [physicalCountModule.FILTER_SEARCH_PLACEHOLDER]:
    "Busca por categoría o estatus",
  [physicalCountModule.DISARD_ITEM_MODAL_TITLE]: "Descartar artículo",
  [physicalCountModule.DISARD_ITEM_MODAL_DESCRIPTION]:
    "El artículo dejará de enlistarse en tu inventario. Por favor confirma si deseas continuar.",
  [physicalCountModule.EMPTY_COUNTINGS]:
    "Você não tem contagens de inventário programadas",
  [physicalCountModule.CONSOLIDATED_EXPORT_TITLE]: "Relatório Consolidado",
  [physicalCountModule.CONSOLIDATED_EXPORT_SUBTITLE]:
    "Insira as datas para consultar o relatório consolidado.",
  [physicalCountModule.FINISH_ITEM_MODAL_TITLE]: "Contagem final",
  [physicalCountModule.FINISH_ITEM_MODAL_DESCRIPTION]:
    "Este item será contado. Confirme se deseja continuar.",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_TITLE]: "Terminar contagem",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_MESSAGE]:
    "Se você quiser encerrar a contagem? após o término da contagem, não será possível alterar as quantidades..",
  [physicalCountModule.SCHEDULED]: "Programado",
  [physicalCountModule.IN_PROCESS]: "Em proceso",
  [physicalCountModule.FINISHED]: "Finalizado",

  [purchaseOrderModule.DETAIL_PAGE_TITLE]: "Líneas de órdenes de compra",
  [purchaseOrderModule.RECORDS_PAGE_SUBTITLE]:
    "Selecione o pedido de compra que deseja receber.",
  [purchaseOrderModule.FIND_ORDERS]: "Pesquisar Ordem de Compra",
  [purchaseOrderModule.RECORDS_PAGE_TITLE]: "Recepção de Pedidos",
  [purchaseOrderModule.PROGRESS]: "Progresso da Recepção",
  [purchaseOrderModule.ORDER_ID]: "ID DO PEDIDO",
  [purchaseOrderModule.RECEPTION]: "ID DE LA RECEPÇÃO",
  [purchaseOrderModule.TYPE]: "Tipo de Pedido",
  [purchaseOrderModule.REQUEST_DATE]: "Data de Aplicação",
  [purchaseOrderModule.ITMES]: "Itens Solicitados",
  [purchaseOrderModule.UNIT]: "UNIDADE",
  [purchaseOrderModule.FIND_ITEM]: "Item de Pesquisa",
  [purchaseOrderModule.STATUS_RECEIPT]: "Status de recepção",
  [purchaseOrderModule.ADD_DOCUMENTS]: "Adicionar documentos",
  [purchaseOrderModule.DOCUMENTS_LIST_TITLE]: "Documentos carregados",
  [purchaseOrderModule.DOCUMENTS_LIST_INFO]:
    "Visualize ou baixe o documento que você precisa.",
  [purchaseOrderModule.RECEIPT]: "Receber pedido de compra",
  [purchaseOrderModule.TITLE]: "Revisar pedido de compra",
  [purchaseOrderModule.DESCRIPTION]:
    "Consulta de pedidos de compra solicitados aos fornecedores.",
  [purchaseOrderModule.FILTER_ORDERS]: "Filtrar pedidos de compra",
  [purchaseOrderModule.FIND_ORDER_LABEL]: "ID do pedido / nome do fornecedor",
  [purchaseOrderModule.PARTIAL_ORDERS]: "Pedidos parciais",
  [purchaseOrderModule.PENDING_ORDERS]: "Pedidos pendentes",
  [purchaseOrderModule.COMPLETE_ORDERS]: "Pedidos completos",
  [purchaseOrderModule.PERCENTAGE_TO_ORDERS]:
    "{{percentage}}% do total de pedidos",
  [purchaseOrderModule.ADD_DOCUMENTS_LABEL_INFO]:
    "Tire uma foto ou selecione o documento",
  [purchaseOrderModule.INVOICES]: "Facturas",
  [purchaseOrderModule.DOWNLOAD_OCS]: "Pedidos de compra",
  [purchaseOrderModule.DOWNLOAD_LINES]: "Detalhes do pedido de compra",
  [purchaseOrderModule.INVOICE_ID]: "ID da fatura",
  [purchaseOrderModule.VENDOR_CODE]: "Codigo do fornecedor",
  [purchaseOrderModule.NOTES]: "Notas",
  [purchaseOrderModule.INVOICE_TITLE]: "Gerenciar faturas",
  [purchaseOrderModule.INVOICE_DESCRIPTION]:
    "Gerenciar faturas recebidas e associar pedidos de compra",
  [purchaseOrderModule.FILTER_INVOICES]: "Filtrar faturas",
  [purchaseOrderModule.IMPORT_TITLE]: "Importar fatura xml",
  [purchaseOrderModule.WATCH_ORDER]: "Ver pedido",
  [purchaseOrderModule.LINK_ORDER]: "Associado",
  [purchaseOrderModule.INVOICE_REPORT]: "Faturas",
  [purchaseOrderModule.INVOICE_REPORT_DETAILED]: "Detalhes da fatura",

  [suppliesManagerModule.TITLE]: "Gerenciamento de Suprimentos para Inventário",
  [suppliesManagerModule.SUBTITLE]:
    "Ativar ou desativar os suprimentos para contagem de estoque.",
  [suppliesManagerModule.MASSIVE_SUCCESS_TITLE]: "Gerenciamento de insumos",
  [suppliesManagerModule.MASSIVE_SUCCESS_MESSAGE]:
    "Insumos corretamente atualizados.",

  [adjustmentsModule.TITLE]: "Ajuste de Estoques",
  [adjustmentsModule.SUBTITLE]: "Atualizar estoques com ajustes manuais",
  [adjustmentsModule.INSTRUCTIONS]: "Instruções",
  [adjustmentsModule.INSTRUCTIONS_DETAILS]:
    "Importe o modelo com as configurações correspondentes para atualizar o inventário real.",
  [adjustmentsModule.BOX_DETAILS]:
    "Selecione ou arraste o modelo com configurações",
  [adjustmentsModule.MSG_OK_START_PROCCESS]: "Processo iniciado corretamente",
  [adjustmentsModule.MSG_FAIL_START_PROCCESS]: "Falha no processo",
  [adjustmentsModule.SUCCESSFULLY_SAVE]: "Ajuste salvo com sucesso",

  [dailyAdjustmentsModule.TITLE]: "Log de ajustes",
  [dailyAdjustmentsModule.TITLE_DISCARDED]: "Registro de perdas",
  [dailyAdjustmentsModule.SUBTITLE_DISCARDED]:
    "Registra as perdas geradas em produtos de cozinha.",
  [dailyAdjustmentsModule.INSTRUCTIONS_DISCARDED]:
    "Pesquise o produto para o qual deseja reportar os resíduos gerados.",

  [dailyAdjustmentsModule.TITLE_LOSS]: "Registro de desperdícios",
  [dailyAdjustmentsModule.SUBTITLE_LOSS]:
    "Recorde de desperdícios em produtos de cozinha.",
  [dailyAdjustmentsModule.INSTRUCTIONS_LOSS]:
    "Pesquise o produto para o qual deseja relatar o cancelamento gerado.",

  [dailyAdjustmentsModule.TITLE_CONSUMPTION]: "Registro de consumo",
  [dailyAdjustmentsModule.SUBTITLE_CONSUMPTION]:
    "Registra o consumo gerado a partir de produtos de cozinha.",
  [dailyAdjustmentsModule.INSTRUCTIONS_CONSUMPTION]:
    "Pesquise o produto para o qual deseja informar o consumo gerado.",

  [dailyAdjustmentsModule.TITLE_EVENT]: "Registro de eventos",
  [dailyAdjustmentsModule.SUBTITLE_EVENT]:
    "Registra eventos gerados a partir de produtos de cozinha.",
  [dailyAdjustmentsModule.INSTRUCTIONS_EVENT]:
    "Pesquise o produto ao qual deseja reportar o evento gerado.",

  [dailyAdjustmentsModule.TITLE_FINISHED_PRODUCT]:
    "Cadastro de produtos acabados",
  [dailyAdjustmentsModule.SUBTITLE_FINISHED_PRODUCT]:
    "Regista os produtos acabados gerados a partir de produtos de cozinha.",
  [dailyAdjustmentsModule.INSTRUCTIONS_FINISHED_PRODUCT]:
    "Procure o produto para o qual deseja reportar os produtos acabados gerados.",

  [dailyAdjustmentsModule.REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION]:
    "Pesquise o insumo ou produto que deseja denunciar",

  [dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION]:
    "Autorização de Configurações",
  [dailyAdjustmentsModule.VALIDATE_AUTHORIZATION]:
    "Validar registros de movimento gerados pela equipe da cozinha",
  [dailyAdjustmentsModule.CONFIRM_MESSAGE]:
    "{{quantity}} {{unit}} será registrado como {{type}} para esta entrada. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE]:
    "O movimento selecionado será autorizado. Por favor confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL]:
    "Os {{supplyQuantity}} movimentos selecionados serão autorizados. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_TITLE]: "Excluir movimentos",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE]:
    "O movimento selecionado será excluído. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE_PLURAL]:
    "Os {{supplyQuantity}} movimentos selecionados serão excluídos. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_TITLE]: "Validar movimentos",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE]:
    "O movimento selecionado será validado. Por favor confirme se deseja continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE_PLURAL]:
    "Os {{supplyQuantity}} movimentos selecionados serão validados. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsModule.REPORT_EVENT_TITLE]: "Relatar eventos",
  [dailyAdjustmentsModule.SHOW_FINISHED_PRODUCTS]: "Mostrar produtos acabados",
  [dailyAdjustmentsModule.PRODUCT_DRAWER_INFO]: "Valide as entradas usadas",
  [dailyAdjustmentsModule.PRE_AUTHORIZE_ADJUSMENT_BUTTON]: "Pré-autorizar",
  [theoricalInventoryModule.TITLE]: "Inventário teórico",
  [theoricalInventoryModule.NO_ELEMENTS]: "Nenhum artigo",
  [theoricalInventoryModule.SUBTITLE]:
    "Consulta de inventário teórico por cozinha",
  [theoricalInventoryModule.SUPPLIES_EXCLUDED]: "Suprimentos Excluídos",
  [theoricalInventoryModule.DEVIATION_ZERO]: "Sem Desvio",
  [theoricalInventoryModule.DEVIATION_LOW]: "Desvio Tolerável",
  [theoricalInventoryModule.DEVIATION_HIGH]: "Desvio Alto",
  [theoricalInventoryModule.REPORT_TITLE]: "Relatório de inventário",
  [theoricalInventoryModule.REPORT_SUBTITLE]:
    "Valida o inventário teórico vs a contagem física.",
  [theoricalInventoryModule.REPORT_FILTER]: "Filtrar relatório",
  [theoricalInventoryModule.ERROR_DATA]: "Ocorreu um erro ao obter os dados",
  [theoricalInventoryModule.ERROR_EXPORT]:
    "Nenhum registro encontrado para exportar",
  [theoricalInventoryModule.THEORETICAL_AMOUNT]: "Quantidade Teórica",
  [theoricalInventoryModule.ACTUAL_AMOUNT]: "Valor real",
  [theoricalInventoryModule.HIGH_ACCURACY]: "Accuracy Alto",
  [theoricalInventoryModule.LOW_ACCURACY]: "Accuracy Baixa",
  [theoricalInventoryModule.MEDIUM_ACCURACY]: "Accuracy Média",
  [theoricalInventoryModule.DIFFERENCE]: "Diferença",
  [theoricalInventoryModule.DEVIATION]: "Desvio",
  [theoricalInventoryModule.LAST_COUNTING_LABEL]:
    "Last inventory count performed on {{date}} at {{time}}",
  [theoricalInventoryModule.RESULT]: "Resultado",
  [theoricalInventoryModule.ACCURACY_RESULT]: "Accuracy de {{accuracy}}%",
  [kitchenInventoryManagement.INVENTORY_MODULE]:
    "Gestão de Estoques para Cozinhas",
  [kitchenInventoryManagement.INVENTORY_MODULE_DESCRIPTION]:
    "Selecione a ferramenta que deseja usar para gerenciamento de estoque em sua cozinha.",

  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE]:
    "Administrador de inventário",
  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE_DESCRIPTION]:
    "Selecione a ferramenta que deseja usar para gerenciar as configurações de inventário.",

  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE]: "Administrador de compras",
  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE_DESCRIPTION]: "",

  [kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE]: "Inventário",
  [kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION]:
    "Contagens agendadas para registro de inventário.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE]: "Movimentos diários",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION]:
    "Relatório de ajustes por perdas, distratos, consumos e eventos.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_CP]:
    "Gestão de Inventários para Centros de Produção",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION_CP]:
    "Selecione a ferramenta que deseja utilizar para a gestão de inventário em seu centro de produção.",

  [kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE]: "Recepção de pedidos",
  [kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION]:
    "Recepção de transferências e ordens de compra.",
  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE]:
    "Autorizar recontagens",
  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION]:
    "Autorização de contagens solicitadas por localidades.",
  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE]:
    "Autorização de movimentos",
  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION]:
    "Revisão dos ajustes de estoque para movimentações diárias.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE]: "Registro de produções",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION]:
    "Programação e geração de produções.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION_2]:
    "Criação de produções para transferências",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODULE]:
    "Lista de produções ativas no Centro de Produção.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_TITLE]:
    "Atualmente não há produções ativas.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_INSTRUCTION]:
    "Iniciar uma nova produção para continuar",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_SCHEDULE_PRODUCTIONS]:
    "Produções programadas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ACTIVE_PRODUCTIONS]:
    "Produções ativas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ENDED_PRODUCTIONS]:
    "Produções concluídas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NEW_PRODUCTIONS]:
    "NOVA PRODUÇÃO",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ADD_PRODUCTIONS]:
    "ADICIONAR PRODUÇÃO",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NAME_PRODUCTIONS]:
    "Nome da produção",

  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE]:
    "Agendamento de transferência",
  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION]:
    "Programación de intercambio de productos entre ubicaciones.",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_TITLE]:
    "Preparação de transferência",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_DESCRIPTION]:
    "Seleção e embalagem de suprimentos para transferências marítimas.",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE]:
    "Autorização de Transferência",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION]:
    "Revisão de agendamento de transferências reportadas entre localidades.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE]: "Verificar inventários",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE]: "Baixar contagem",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE]: "Controlar entrada",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE]: "Ajuste massivo",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE]:
    "Comparar inventário",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE]: "Ajuste único",
  [kitchenInventoryManagement.DAILYMOVS_MODULE]:
    "Atualização de ajuste de estoque individual.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE_DESCRIPTION]:
    "Análise e revisão de inventário teórico.",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE_DESCRIPTION]:
    "Baixe contagens de inventário por local.",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE_DESCRIPTION]:
    "Ativação e desativação de suprimentos para estoques.",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE_DESCRIPTION]:
    "Atualização em massa das configurações de inventário.",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION]:
    "Análise e validação de inventário teórico vs físico.",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE_DESCRIPTION]:
    "Actualización individual de ajustes de inventario.",
  [kitchenInventoryManagement.DAILYMOVS_MODULE_DESCRIPTION]:
    "Relatório de movimentos diários reportados por localização",

  [kitchenInventoryManagement.SELECT_MODULE_TITLE]: "Selecionar módulo",
  [kitchenInventoryManagement.SELECT_MODULE_SUBTITLE]: "Selecionar ferramenta",
  [kitchenInventoryManagement.FOR_SUPPLY_INFO]:
    "Selecione a entrada que deseja iniciar a preparação das transferências para os locais solicitados",
  [kitchenInventoryManagement.FOR_KITCHEN_INFO]:
    "Escaneie as embalagens ou insira a quantidade de cada insumo que deseja embalar para a cozinha",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_TITLE]:
    "Recepção de Fornecimento",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION]:
    "Preparação de suprimentos para locais.",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_TITLE]:
    "Liberação de produção",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_DESCRIPTION]:
    "Validação e liberação das produções geradas.",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE]: "Integração",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE_DESCRIPTION]:
    "Integração Business Central",

  [productionManagement.PRODUCT_INSTRUCTION]:
    "Selecione uma produção para ver os ingredientes necessários.",

  [productionManagement.START_PRODUCTION]: "Iniciar a produção",
  [productionManagement.FINISH_PRODUCTION]: "CONCLUIR A PRODUÇÃO",
  [productionManagement.FINISH_PRODUCTION_MODAL]:
    "Insira o rendimento real da produção realizada.",
  [productionManagement.FINISH_REAL_PERFORMANCE]: "O Rendimiento Real",
  [productionManagement.PRODUCTION_USER]: "Gestor de produção",
  [productionManagement.PRODUCTION_PACKED_QUANTITY]: "Quantidade por pacote",
  [productionManagement.PRODUCTION_CALCULATOR_TITLE]: "Calcular a produção",
  [productionManagement.PRODUCTION_CALCULATOR_DESCRIPTION]:
    "Digite o insumo com o qual você deseja produzir para calcular o rendimento",
  [productionManagement.PRODUCTION_CALCULATOR_ESTIMATED]: "Produção estimada",
  [productionManagement.EDIT_PRODUCTION]: "Modificar produção",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_TITLE]:
    "Produção modificada",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE]:
    "A produção de {{productionName}} foi atualizada com sucesso.",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_HIGH]:
    "O rendimento real {{producedQuantity}} {{unit}} para a produção {{productionName}} é muito ALTO para a quantidade a ser produzida {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_LOW]:
    "O rendimento real {{producedQuantity}} {{unit}} para a produção {{productionName}} é muito BAIXO para a quantidade a ser produzida {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_VALIDATION_MESSAGE]:
    "A produção de <strong>{{productionName}}</strong> requer a produção prévia de <strong>{{itemName}} </strong>.",
  [productionManagement.REPORT_PRODUCTION_ERROR_PRODUCTION_VALIDATION_MESSAGE]:
    "A produção de <strong>{{productionName}}</strong> requer uma produção anterior de <strong>{{itemName}} </strong>. <strong>Gere primeiro a produção de {{itemName}} para continuar </strong>.",
  [productionManagement.DELETE_DIALOG_TITLE]: "Excluir produção",
  [productionManagement.DELETE_DIALOG_MESSAGE]:
    "A produção de {{productionName}} será removida da lista de produções ativas. Por favor, confirme se deseja continuar?",
  [productionManagement.PACKAGING_PARTIAL]: "Para ser embalado",
  [productionManagement.PACKAGING_COMPLETE]: "Produção empacotada",
  [productionManagement.LABELS_HISTORY]: "Histórico de embalagem",
  [productionManagement.LABELS_HISTORY_EMPTY]:
    "A embalagem para esta produção ainda não foi iniciada.",
  [productionManagement.PACK_PRODUCTION]: "Produção do pacote",
  [productionManagement.SELECT_LABELS_TO_PRINT]:
    "Selecione as etiquetas que deseja imprimir",
  [productionManagement.REPRINT_LABELS]: "Imprimir etiquetas",
  [productionManagement.LABELS_OF]: "Embalagem de {{quantity}} {{unit}}",
  [productionManagement.LABELS_GENERATED]: "Etiquetas geradas",
  [productionManagement.LABEL]: "Etiqueta",
  [productionManagement.USER_CREATED_LABELS]: "Gerente de produção",
  [productionManagement.SELECT_USER_CREATED_LABELS]: "Selecionar gerente",
  [productionManagement.LABELS_GENERATED_INFO]:
    "Adiciona as informações para gerar as etiquetas das embalagens",
  [productionManagement.TRIGGER]: "Gerar",
  [productionManagement.QUANTITY_TO_PACK]: "Quantidade a embalar",
  [productionManagement.QUANTITY_OF_PACKAGING]: "Nº embalagem",
  [productionManagement.LOG_TITLE]: "Acompanhar produção",
  [productionManagement.LOG_DESCRIPTION]:
    "Revisão do histórico de movimentação de embalagens.",
  [productionManagement.LOG_TITLE_INPUT]: "Pesquisar ou digitalizar produção",
  [productionManagement.LOG_LABEL_INPUT]: "Insira o lote de produção",
  [productionManagement.LOG_TRACK]: "Track",
  [productionManagement.LOG_EMPTY_LABEL_1]: "Acompanhamento de produção",
  [productionManagement.LOG_EMPTY_LABEL_2]:
    "Insira ou digitalize uma produção para validar seus movimentos registrados",
  [productionManagement.LOG_DETECTED_MOVS]: "Movimentos detectados",
  [productionManagement.LOG_MOV]: "Movimento",
  [productionManagement.LOG_VALIDITY_LABEL]:
    "Validade: {{days}} dias restantes",
  [productionManagement.LOG_INVALIDITY_LABEL]: "Expirou há {{days}} dias",
  [productionManagement.SCHEDULED]: "Agendado",
  [productionManagement.IN_PROCESS]: "Produzindo",
  [productionManagement.FINISHED]: "Concluído",
  [productionManagement.APPROVED]: "Aprovado",
  [productionManagement.APPROVED_WITH_NOVELTY]: "Aprovado com novidade",
  [productionManagement.PRODUCTION_AND_QUANTITY]: "Produção e quantidade",
  [productionManagement.CHRONOMETER]: "Cronômetro",
  [productionManagement.RESPONSIBLE]: "Responsável",
  [productionManagement.SCHEDULED_QUANTITY]: "Quantidade programada",
  [productionManagement.PRODUCED_QUANTITY]: "Quantidade produzida",
  [productionManagement.STARTED_PRODUCTION]: "Início da produção",
  [productionManagement.FINISHED_PRODUCTION]: "Fim da produção",
  [productionManagement.PRODUCTION_TIME]: "Tempo de produção",
  [productionManagement.PRODUCTION_USER_ASSIGNED_REQUIRED]:
    "O responsável pela produção é obrigatório",
  [productionManagement.PRODUCTION_SCHEDULED_DATE_REQUIRED]:
    "A data de produção é obrigatória",
  [productionManagement.PRODUCTION_QUANTITY_REQUIRED]:
    "A quantidade a ser produzida é obrigatória",
  [productionManagement.PRODUCTION_QUANTITY_TYPE_ERROR]:
    "A quantidade a ser produzida deve ser um número",
  [productionManagement.PRODUCTION_SKU_REQUIRED]: "O SKU é obrigatório",
  [productionManagement.DOWNLOAD_EXAMPLE_TEMPLATE]:
    "Você pode baixar nosso modelo clicando aqui.",
  [productionManagement.INVALID_DOCUMENT_TITLES]:
    "Os títulos do documento não são válidos.",
  [productionManagement.INVALID_DOCUMENT_DATA]:
    "Os dados do documento não são válidos.",
  [productionManagement.DRAG_AND_DROP_FILE]:
    "Selecione ou arraste o modelo com as produções.",
  [productionManagement.FILL_IN_THE_FORM]:
    "Insira os dados da produção que você deseja adicionar.",
  [productionManagement.ADD_PRODUCTION_TITLE]: "Adicionar produção",
  [productionManagement.EDIT_PRODUCTION_TITLE]: "Editar produção",
  [productionManagement.VALIDATE_ADDED_DATA]:
    "Confirme os dados da produção adicionada.",
  [productionManagement.PRODUCTION_NAME]: "Nome da produção",
  [productionManagement.QUANTITY]: "Quantidade a ser produzida",
  [productionManagement.SCHEDULED_DATE]: "Data de produção",
  [productionManagement.TIME_SLOT]: "Turno de produção",
  [productionManagement.USER_ASSIGNED]: "Responsável pela produção",
  [productionManagement.PRODUCING_FOR]: "Produzindo há muito tempo",
  [productionManagement.PRINT_INGREDIENTS]: "Imprimir ingredientes",
  [productionManagement.PPRODUCTION_ORDER]: "Ordem de produção",
  [productionManagement.GENERATED_QUANTITY]: "Quantidade gerada",
  [productionManagement.VALIDATION_REQUIRED_INFO]:
    "Produção pendente de validação pela Qualidade",

  [transferModule.CONFIRM]: "Confirmar transferência",
  [transferModule.NUMBER_OF_BASKETS]: "Número de cestas",
  [transferModule.SUCCESS_AUTHORIZE_TITLE]: "Transferência confirmada",
  [transferModule.SUCCESS_AUTHORIZE_DESCRIPTION]:
    "A transferência foi confirmada com sucesso no sistema de inventário.",
  [transferModule.ERROR_AUTHORIZE_TITLE]:
    "A transferência não pôde ser confirmada",
  [transferModule.NUMBER_OF_BASKETS_QUESTION]: "Quantas cestas serão enviadas?",
  [transferModule.SUCCESS]: "Transferência concluída com sucesso",
  [transferModule.DELETE_LABELS]: "Excluir rótulos",
  [transferModule.ERROR_SKU]: "O número do produto está vazio o inválido",
  [transferModule.ERROR_MEASURE_UNIT]: "A unidade de medida está vazia",
  [transferModule.ERROR_LINE]: "Erro na linha",
  [transferModule.DELETE_TRANSFER]: "Excluir transferência",
  [transferModule.DELETE_TRANSFER_CONFIRM]:
    "Você deseja remover a transferência para a cozinha",

  [transferModule.USER_CREATE]: "Criada por",
  [transferModule.USER_RECIBE]: "Recebido por",
  [transferModule.DESTINATION]: "Destino",
  [transferModule.STORAGE]: "Armazenamento",
  [transferModule.EDIT_TRANSFER]: "Editar",
  [transferModule.PREPARE_TRANSFER]: "Preparar",
  [transferModule.ENLISTED]: "Alistado",
  [transferModule.PENDING]: "Pendente",
  [transferModule.UNSPECIFIED]: "Não especificado",
  [transferModule.RECEIPT_NO]: "Número de recepção",
  [transferModule.ORDER_NO]: "Número de transferência",
  [transferModule.RECEIVED_AT]: "Data de recebimento",
  [transferModule.RECEIVED_BY]: "Recebido por",
  [transferModule.CREATED_AT]: "Data de criação",
  [transferModule.STATUS]: "Estado",
  [transferModule.NOT_PENDIG]:
    "Você não tem ordens de transferência pendentes para enviar.",
  [transferModule.ERROR_SINGLE_TRANSFER]:
    "O destino da remessa deve ser diferente da origem.",
  [transferModule.TITLE_ERROR_PENDING_TRANSFER]:
    "Você tem as seguintes transferências pendentes de sincronização:",
  [transferModule.DESCRIPCTION_ERROR_PENDING_TRANSFER]:
    "Verifique se você tem alguma produção pendente para relatar ou pedidos pendentes para receber. Caso contrário, entre em contato com o Gerente de Inventário.",
  [transferModule.SCHEDULE]: "Agendar transferência",
  [transferModule.MASSIVE]: "Transferência em massa",
  [transferModule.INDIVIDUAL]: "Transferência individual",
  [transferModule.TO_SEND]: "Transferências pendentes",
  [transferModule.MADE]: "Transferências autorizadas",

  [transferModule.RETURN_MESSAGE]:
    "Os seguintes produtos serão devolvidos para ",
  [transferModule.RECEIPT_CONFIRM_MESSAGE]:
    "A transferência foi recebida com sucesso",
  [transferModule.RECEIPT_CONFIRM_TITLE]: "Recepção bem-sucedida",
  [transferModule.RECEIPT_MESSAGE]:
    "Confirmar o recebimento da ordem de transferência",
  [transferModule.RECEIPT_TITLE]: "Receber transferência",
  [transferModule.EXTRA]: "EXTRA",
  [transferModule.TRANSFER]: "TRANSFERÊNCIA",
  [transferModule.RETURN]: "RETORNO",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_QUESTION]:
    "Nessa transferência chegaram insumos que não estavam na ordem de recebimento ou com quantidades excedentes?",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_COMMENT]:
    "Indique os insumos e quantidades",
  [transferModule.RECEIPT]: "Receber transferência",

  [transferPackingDialog.SUBTITLE]:
    "Selecione a cozinha e comece a digitalizar a embalagem que deseja adicionar.",
  [transferPackingDialog.SUBTITLE_FOR_KITCHEN]:
    "Validar os pacotes verificados.",
  [transferPackingDialog.SUBTITLE_DELETE_LABELS]:
    "Digitalize a embalagem que deseja eliminar.",
  [transferPackingDialog.RESUME]: "Resumo da transferência",
  [transferPackingDialog.SUPPLY]: "Suprimento",
  [transferPackingDialog.TOTAL_QUANTITY]: "Quantidade Total",
  [transferPackingDialog.CONTINUE]: "Continuar",
  [transferPackingDialog.CANCEL]: "Cancelar",
  [transferPackingDialog.REQUESTED_QUANTITY]: "Quantidade solicitada",
  [transferPackingDialog.SERVICE_LEVEL]: "Nível de Serviço",
  [transferPackingDialog.QUANTITY_SENT]: "Quantidade enviada",
  [transferPackingDialog.CONFIRMED_TRANSFERS_TITLE]:
    "Transferências confirmadas",
  [transferPackingDialog.CONFIRMED_TRANSFERS_MESSAGE]:
    "As quantidades enviadas para as cozinhas foram confirmadas com sucesso no sistema de inventário.",

  [transferOrdersPackedReviewTable.SKU]: "SKU",
  [transferOrdersPackedReviewTable.NAME]: "Nome",
  [transferOrdersPackedReviewTable.UNIT]: "Unidade",
  [transferOrdersPackedReviewTable.ESTIMATED_QUANTITY]: "Quantidade estimada",
  [transferOrdersPackedReviewTable.PACKED_QUANTITY]: "Quantidade embalada",
  [transferOrdersPackedReviewTable.QUANTITY_TO_SEND]: "Quantidade a enviar",
  [transferOrdersPackedReviewTable.SERVICE_LEVEL]: "Nível de serviço",
  [transferOrdersPackedReviewTable.STATUS]: "Status",
  [transferOrdersPackedReviewTable.COMPLETE]: "Completo",
  [transferOrdersPackedReviewTable.PARTIAL]: "Parcial",
  [transferOrdersPackedReviewTable.NO_SENT]: "Não enviado",
  [transferOrdersPackedReviewTable.ALL_TITLE]: "Mostrar Todo",
  [transferOrdersPackedReviewTable.COMPLETE_TITLE]: "Entradas completas",
  [transferOrdersPackedReviewTable.PARTIAL_TITLE]: "Entradas parciais",
  [transferOrdersPackedReviewTable.NO_SENT_TITLE]: "Entradas Não enviado",

  [countingTypeSelector.LABEL]: "Tipo de contagem",

  [physicalCountSchedule.HEADER_PAGE_TITLE]: "Programar inventários",
  [physicalCountSchedule.HEADER_PAGE_SUBTITLE]:
    "Programar contagens de inventário por localização",
  [physicalCountSchedule.STEP_LABEL_1]: "Tipo de contagem",
  [physicalCountSchedule.STEP_LABEL_2]: "Localização",
  [physicalCountSchedule.IMPORT]: "Importar",
  [physicalCountSchedule.STEP_LABEL_3]: "Datas",
  [physicalCountSchedule.STEP_LABEL_4]: "Entradas",
  [physicalCountSchedule.STEP_LABEL_FINAL]: "Confirmar",
  [physicalCountSchedule.PREVIOUS]: "Anterior",
  [physicalCountSchedule.CONTINUE]: "Continuar",
  [physicalCountSchedule.FINISH]: "Concluir",
  [physicalCountSchedule.ADD_LOCATION]: "Adicionar local",
  [physicalCountSchedule.ACTIONS]: "Ações",
  [physicalCountSchedule.STEP_2_TABLE_EMPTY]: "Nenhum local adicionado ainda",
  [physicalCountSchedule.SINGLE_DATE]: "Data única",
  [physicalCountSchedule.DATE_RANGE]: "Período",
  [physicalCountSchedule.COUNT_DATE]: "Data da contagem",
  [physicalCountSchedule.START_DATE]: "Data de início",
  [physicalCountSchedule.END_DATE]: "Data final",
  [physicalCountSchedule.SCHEDULE_DATE]: "Data agendada",
  [physicalCountSchedule.SCHEDULE_DATES]: "Agendar datas",
  [physicalCountSchedule.DATE]: "Data",
  [physicalCountSchedule.STEP_3_TABLE_EMPTY]: "Nenhuma data adicionada ainda",
  [physicalCountSchedule.STEPS_RESET]: "Reiniciar",
  [physicalCountSchedule.STEPS_COMPLETED]: "Todas as etapas foram concluídas",
  [physicalCountSchedule.STEP_1_INFO]:
    "Selecione o tipo de contagem física que deseja programar.",
  [physicalCountSchedule.STEP_2_INFO]:
    "Selecione os locais para os quais deseja agendar a contagem física.",
  [physicalCountSchedule.STEP_IMPORT_INFO]:
    "Selecione ou arraste o modelo de recontagem",
  [physicalCountSchedule.STEP_3_INFO]:
    "Selecione as datas que deseja agendar para a contagem do estoque físico.",
  [physicalCountSchedule.STEP_4_INFO]:
    "Selecione os insumos que deseja agendar a contagem física.",
  [physicalCountSchedule.STEP_FINAL_INFO]:
    "Valida os dados antes de finalizar a programação da contagem física.",
  [physicalCountSchedule.STEP_4_TABLE_EMPTY]: "Não há entradas para exibir",
  [physicalCountSchedule.SUCCESS_TITLE]: "Contagem programada",
  [physicalCountSchedule.SUCCESS_SUBTITLE]:
    "A contagem física foi agendada com sucesso.",
  [physicalCountSchedule.ERROR_TITLE]: "Contagem não programada",
  [physicalCountSchedule.ERROR_SUBTITLE]:
    "Oops... Ocorreu um erro. A contagem física não foi agendada.",
  [physicalCountSchedule.ARCHIVE_ERROR]: "Erro ao arquivar contagem(s)",
  [physicalCountSchedule.ARCHIVE_SUCCESS]: "Contagens arquivadas com sucesso",

  [productionPreparation.INSTRUCTIONS_FOR_PREPARATION]:
    "Instruções para preparação",
  [productionPreparation.NO_PREPARATION_INSTRUCTIONS_FOUND]:
    "Nenhuma instrução de preparação encontrada",
  [productionPreparation.PRINT_PREAPARATION]: "Preparação de impressão",

  [purchaseOrderModule.MARK_ASRECEIVED_TITLE]: "Pedido de compra recebido",
  [purchaseOrderModule.MARK_ASRECEIVED_MESSAGE]:
    "O pedido de compra será marcado como recebido e ficará pendente para reconciliação.",
  [purchaseOrderModule.DELETE_RECEIPT_TITLE]: "Eliminar ordem de recebimento",
  [purchaseOrderModule.DELETE_RECEIPT_MESSAGE]:
    "Você deseja excluir a ordem do número do recibo {{receiptNo}}%",

  [purchaseOrderLine.TITLE]: "Detalhe do pedido de compra",
  [purchaseOrderLine.DELETE_RECEIPT]: "Excluir recibo",
  [purchaseOrderLine.DIALOG_CONFIRM_TITLE]: "Excluir recibo",
  [purchaseOrderLine.DIALOG_CONFIRM_MESSAGE]:
    "O recibo será excluído e os produtos parciais e pendentes não serão mais entregues no local. Confirme se deseja continuar.",
  [purchaseOrderLine.DIALOG_SUCCESS_TITLE]: "Recibo removido",
  [purchaseOrderLine.DIALOG_SUCCESS_MESSAGE]:
    "O recibo foi removido com sucesso. Os produtos em falta não serão mais recebidos pelo local atribuído à ordem de compra.",
  [purchaseOrderLine.DIALOG_ERROR_TITLE]: "Recibo não excluído",
  [purchaseOrderLine.DIALOG_ERROR_MESSAGE]: "Ocorreu um erro",
  [purchaseOrderLine.FIND_PRODUCT_TITLE]: "Encontrar produto",
  [purchaseOrderLine.FIND_PRODUCT_LABEL]: "ID do produto / Nome do produto",
  [purchaseOrderLine.RECEIPT_PENDING]:
    "Para receber: {{pendingLines}} suprimentos",
  [purchaseOrderLine.RECEIPT_COMPLETE]: "Recibo concluído",
  [purchaseOrderLine.UPDATE_RECEIVED_QUANTITY_ERROR]:
    "Excede em {{num}} o solicitado",

  [purchaseOrderCreation.TITLE]: "Gerar pedidos de compra",
  [purchaseOrderCreation.DESCRIPTION]:
    "Geração e envio de pedidos de compra a fornecedores",
  [purchaseOrderCreation.CREATE_TITLE]: "Criar pedidos de compra",
  [purchaseOrderCreation.CREATE_MASSIVE_BUTTON]: "Carga a granel",
  [purchaseOrderCreation.CREATE_INDIVIDUAL_BUTTON]: "Carga única",
  [purchaseOrderCreation.CONFIRM_BUTTON]: "Confirmar pedidos de compra",
  [purchaseOrderCreation.DETECTED_ORDERS_TITLE]: "Peidos encontrados",
  [purchaseOrderCreation.NO_ORDENES_DETECTED]:
    "Nenhum pedido de compra encontrado",
  [purchaseOrderCreation.NO_ORDERS_FOR_SEARCH]:
    "Nenhuma correspondência encontrada para sua pesquisa",
  [purchaseOrderCreation.CREATE_MASSIVE_MODAL_TITLE]:
    "Crie pedidos de compra massivamente",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_TITLE]:
    "Confirmar pedidos de compra",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_SUBTITLE]:
    "Confirme os seguintes pedidos de compra: ",
  [purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABEL]: "Entrega estimada",
  [purchaseOrderCreation.VALIDATE_ITEM_MESSAGE]:
    "Adicionar ou remover produtos deste pedido de compra",
  [purchaseOrderCreation.CONFIRM_AND_SEND_BUTTON]: "Confirmar e enviar",
  [purchaseOrderCreation.DELETE_ORDER]: "Excluir pedido",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_TITLE]: "Alterar data de entrega",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_INFO]:
    "Selecione a data estimada para o pedido de compra",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_BUTTON]: "Atualizar data estimada",
  [purchaseOrderCreation.ESTIMATED_DATE_LABEL]: "Data estimada",
  [purchaseOrderCreation.INITIAL_ESTIMATED_DATE_LABEL]: "Data estimada inicial",
  [purchaseOrderCreation.DELETE_LINE_MODAL_TITLE]: "Eliminar produto",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE]:
    "Excluir pedido de compra",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE]:
    "Você deseja excluir o pedido de compra do vendedor ",
  [purchaseOrderCreation.DELETE_LINE_MODAL_SUBTITLE]:
    "Remover produtos do pedido de compra:",
  [purchaseOrderCreation.ADD_LINE_MODAL_TITLE]: "Adicionar produtos",
  [purchaseOrderCreation.ADD_LINE_MODAL_INFO]:
    "Adicione informações para atualizar o pedido de compra",
  [purchaseOrderCreation.ADD_LINE_MODAL_BUTTON]:
    "Adicionar ao pedido de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_TITLE]: "Confirmar pedido de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_SUBTITLE]:
    "Confirme os seguintes pedidos de compra:",
  [purchaseOrderCreation.SEND_ORDER_MODAL_INFO]:
    "Adicione as informações para enviar o pedido de compra",
  [purchaseOrderCreation.SEND_LOADING_MODAL]: "Confirmando pedido de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_BUTTON]: "Enviar pedido de compra",
  [purchaseOrderCreation.ORDER_CONFIRMED]: "Pedido confirmado",
  [purchaseOrderCreation.ORDERD_CONFIRMED_OK]: "Ok",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_TITLE]:
    "Adicionar pedido de compra",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INFO]:
    "Adicione as informações para criar o pedido de compra",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_BUTTON]:
    "Adicionar pedido de compra",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_LOCATION_LABEL]: "Local de entrega",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_VENDOR_LABEL]: "Nome do provedor",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL]: "Número da fatura",
  [purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL]: "Ver documentos",
  [purchaseOrderCreation.RESULT_DIALOG_TITLE]:
    "Resultado da criação do pedido de compra",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_VENDOR]:
    "Fornecedor com código {{code}} não foi encontrado no pedido com número {{invoiceNo}}",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_SKU]:
    "A entrada com SKU {{code}} não foi encontrada no pedido com número {{invoiceNo}}",

  [purchaseOrderCreation.RESULT_NO_ERRORS]:
    "Nenhum erro foi encontrado na criação do pedido de compra",
  [productionDialog.WEIGHT]: "PESO",
  [productionDialog.BATCH]: "LOTE",
  [productionDialog.TECHNICAL_NAME]: "NOME TÉCNICO",
  [productionDialog.NAME]: "PRODUTO",
  [productionDialog.PRODUCTION_DATE]: "DATA DE PRODUÇÃO",
  [productionDialog.EXPIRATION_DATE]: "DATA DE VENCIMENTO",
  [productionDialog.CONSERVATION]: "CONSERVAÇÃO",
  [productionDialog.MAXIMUM_DURATION]: "DURAÇÃO MÁXIMA",
  [productionDialog.RESPONSIBLE]: "ENCARREGADO",
  [productionDialog.MANUFACTURER]: "FABRICANTE",
  [productionDialog.ADDRESS]: "DIREÇÃO",
  [productionDialog.WEEK]: "SEMANA",
  [productionDialog.DAYS]: "Dias",

  [errorCode.TRANSFER_WITHOUT_STOCK]:
    "Não há estoque suficiente para os seguintes itens:",
  [errorCode.PARSING_EXCEPTION]: "Error procesando el xml intente de nuevo",
  [errorCode.VENDOR_NOT_FOUND]: "O fornecedor não está cadastrado",

  [massiveTransfer.SELECT_MOVE_FILE]:
    "Selecione ou arraste o modelo com as transferências.",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_BUTTON]:
    "Revisar transferência pendente",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_TITLE]:
    "Transferência pendente",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE]:
    "Você tem uma análise de transferência pendente. Para gerar uma nova transferência, primeiro finalize a atual.",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_TITLE]: "Transferências criadas",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_MESSAGE]:
    "As ordens de transferência foram criadas com sucesso.",

  [dailyAdjustmentsReport.FILTERS_LABEL]: "Filtrar movimentos diários",
  [dailyAdjustmentsReport.SEND_TO_VALIDATION_MESSAGE]:
    "{{supplyQuantity}} suprimentos serão enviados para revisão com o Chefe de Cozinha para receber a confirmação da quantidade informada. Por favor, confirme se deseja continuar.",
  [dailyAdjustmentsReport.APPROVED]: "Autorizado",
  [dailyAdjustmentsReport.VALIDATED]: "Validado",

  [productionScannedError.LABEL_NOT_FOUND]: "Etiqueta não encontrada",
  [productionScannedError.ALREADY_SCANNED]: "Etiqueta já digitalizada",
  [productionScannedError.INTERNAL_ERROR]: "Falha na verificação",
  [productionScannedError.WITHOUT_HISTORIAL]: "Sem histórico",
  [productionScannedError.CAN_NOT_DELETE]: "Não é possível excluir",
  [productionScannedError.SELECT_THE_KITCHEN]: "Você deve selecionar a cozinha",
  [productionScannedError.SELECT_THE_COUNTING]:
    "Você deve selecionar a contagem",
  [productionScannedError.SELECT_ORDER_AND_KITCHEN]:
    "Você deve selecionar o pedido e a cozinha",

  [productionLogReason.CREATED]: "Produção empacotada",
  [productionLogReason.COUNTING]: "Produção inventariada",
  [productionLogReason.TRANSFER_PACKING]: "Produção Transferida",
  [productionLogReason.TRANSFER_RECEIPT]: "Produção recebida",
  [transferOrderModule.TITLE]: "Gerenciar transferências",
  [transferOrderModule.DESCRIPTION]:
    "Revisão de transferências programadas entre localidades.",
  [transferOrderModule.FILTER_ORDERS]: "Filtrar transferências",
  [transferOrderModule.FIND_ORDERS]: "Transferência de pesquisa",
  [transferOrderModule.FIND_ORDER_LABEL]: "ID de transferência/nome do local",
  [transferOrderModule.TRANSFER_ID]: "ID transferência",
  [transferOrderModule.DESTINATION_NAME]: "Nome do local",
  [transferOrderModule.ALL]: "TUDO",
  [transferOrderModule.RELEASED]: "ALISTAMENTO",
  [transferOrderModule.IN_PROCESS]: "ALISTAMENTO",
  [transferOrderModule.PACKED]: "EMBALADO",
  [transferOrderModule.IN_TRANSIT]: "EM TRÂNSITO",
  [transferOrderModule.RECEIVED]: "RECEBIDO",

  [transferOrderDetails.TITLE]: "Detalhe da transferência",
  [transferOrderDetails.FIND_SUPPLY]: "Buscar insumo",
  [transferOrderDetails.FIND_SUPPLY_LABEL]: "ID insumo / Nome insumo",
  [takePhotoLocale.RETRY]: "Tente novamente",
  [takePhotoLocale.CONTINUE]: "Continuar",
  [takePhotoLocale.TAKE_PHOTO]: "Tirar foto",
  [takePhotoLocale.TAKE_PHOTO_AGAIN]: "Voltar para tirar foto",
  [takePhotoLocale.BAG_PRODUCTS]: "Produtos na bolsa",
  [takePhotoLocale.TITLE]: "Tirar foto",

  [purchaseOrderConciliation.TITLE]: "Reconciliar pedidos de compra",
  [purchaseOrderConciliation.DESCRIPTION]:
    "Reconciliação de pedidos de compra solicitados de fornecedores.",

  [productionSchedule.EMPTY_TITLE]: "Você não tem produções agendadas",
  [productionSchedule.EMPTY_DESCRIPTION]:
    "Adicionar uma produção para este horário",
  [productionSchedule.DELETE_TITLE]: "Excluir produção",
  [productionSchedule.DELETE_MESSAGE]:
    "{{productionName}} será removido do plano de produção. Por favor, confirme se deseja continuar?",
  [productionSchedule.TITLE_FINISHED]: "Produção finalizada",
  [productionSchedule.TITLE_IN_PROCESS]: "Produção em processo",
  [productionSchedule.TITLE_SCHEDULED]: "Produção agendada",

  [supplyingManagement.MODULE_TITLE]: "Fornecimento de CP",
  [supplyingManagement.MODULE_DESCRIPTION]:
    "Preparação de suprimentos para locais.",
  [supplyingManagement.RESUME]: "Resumo de requisições",
  [supplyingManagement.TOTAL]: "Totais",
  [supplyingManagement.PARTIAL]: "Parciais",
  [supplyingManagement.COMPLETED]: "Concluído",
  [supplyingManagement.SEARCH]: "Requisição de pesquisa",
  [supplyingManagement.SEARCH_LABEL]: "Nº da requisição.",
  [supplyingManagement.COMPLETE]: "Concluído",
  [supplyingManagement.PENDING]: "Pendente",
  [supplyingManagement.CP_NAME]: "Nome CP",
  [supplyingManagement.SUPPLY_VIEW]: "Visualizar por entradas",
  [supplyingManagement.SLOT_VIEW]: "Visualizar por intervalos de tempo",
  [supplyingManagement.ALL_SLOTS]: "Mostrar tudo",
  [supplyingManagement.ALL_ITEMS]: "Mostrar Tudo",
  [supplyingManagement.ADD_EXTRA_SUPPLY]: "Enviar suprimento extra",
  [supplyingManagement.ADD_EXTRA_SUPPLY_INFO]:
    "Insira os dados da entrada que deseja adicionar.",
  [supplyingManagement.OVER_QUANTITY]: "Quantidade em excesso",
  [supplyingManagement.SUGGESTED_QUANTITY]: "Quantidade sugerida",

  [supplyReception.TITLE]: "Recepção de Fornecimento",
  [supplyReception.RECEIVE_SUPPLIES_TAB]: "Receber entradas",
  [supplyReception.REQUEST_SUPPLIES_TAB]: "Solicitar entradas",
  [supplyReception.REQUISITION_NUMBER_LABEL]: "No. Requisição",
  [supplyReception.TOTAL_REQUISITION_LABEL]: "Requisições Totais",
  [supplyReception.PACKED_REQUISITION_LABEL]: "Requisiçães Empacotada",
  [supplyReception.COMPLETED_REQUISITION_LABEL]: "Requisiçães Concluídas",
  [supplyReception.LOCATION_NAME_LABEL]: "Nome do Cedis",
  [supplyReception.DATE_LABEL]: "Data Requisição",
  [supplyReception.PACKED]: "Empacotado",
  [supplyReception.SENT_QUANTITY]: "Quantidade enviada",
  [supplyReception.RECEIVED_QUANTITY]: "Quantidade recebida",
  [supplyReception.REQUESTED_QUANTITY]: "Quantidade solicitada",
  [supplyReception.NOVELTY]: "Novidade",
  [supplyReception.CONFIRM_SUPPLY]: "Confirmar Fornecimento",
  [supplyReception.NOVELTY_MODAL_TITLE]: "Atualizar Entrada",
  [supplyReception.NOVELTY_MODAL_INFO]:
    "Atualize os detalhes da entrada que você está recebendo.",
  [supplyReception.MISSING_REASON_LABEL]: "Motivo da falta",
  [supplyReception.CONFIRM_SUPPLY_MODAL_TITLE]: "Resumen de Fornecimento",
  [supplyReception.CONFIRM_SUPPLY_MODAL_SUBTITLE]:
    "Confirme os detalhes do fornecimento que você está recebendo.",
  [supplyReception.ITEMS]: "Artigos",
  [supplyReception.PARTIAL_PRODUCTS]: "Produtos Parciais",
  [supplyReception.MISSING_PRODUCTS]: "Produtos Faltantes",
  [supplyReception.SPARE_PRODUCTS]: "Produtos Sobressalentes",
  [supplyReception.COMPLETE_PRODUCTS]: "Produtos Completos",
  [supplyReception.NO_SUPPLIES]:
    "Não há entradas inseridas para fazer a solicitação",
  [supplyReception.NO_SUPPLIES_INFO]:
    "Adicione as entradas que deseja solicitar para o local",
  [supplyReception.CONFIRM_SUPPLY_CREATION_INFO]:
    "Confirme a solicitação de suprimentos",

  [missingReason.DAMAGED_PRODUCT]: "Produto danificado",
  [missingReason.MISSING_PRODUCT]: "Produto faltante",
  [missingReason.PRODUCT_EXPIRED]: "Produto vencido",
  [missingReason.SPARE_PRODUCT]: "Produto sobressalentes",

  [theoricalInventoryModule.DETAILS]: "",

  [productionLiberation.TITLE]: "Liberação de produção",
  [productionLiberation.FINISH_DATE]: "Data de término",
  [productionLiberation.LIBERATE_ACTION_BUTTON]: "Liberar",
  [productionLiberation.RESULT_APPROVEMENT_LABEL]: "Resultado de validação",
  [productionLiberation.APPROVED]: "Aprovado",
  [productionLiberation.APPROVED_WITH_NOVELTY]: "Aprovado com novidade",
  [productionLiberation.REJECTED]: "Rejeitado",
  [productionLiberation.NOVELTY_LABEL]:
    "Insira comentários ou notícias de validação",
  [productionLiberation.EVALUATE_BUTTON]: "Avaliar",
  [productionLiberation.ORGANOLEPTIC_MODAL_TITLE]: "Avaliação de qualidade",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE]:
    "Selecione se o organoléptico atende ou não às características de qualidade",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE_2]:
    "Indique os motivos pelos quais os seguintes organolépticos desta produção não foram atendidos.",
  [productionLiberation.PRODUCTION_ORGANOLEPTIC_ERROR]:
    "Detalhes de produção não encontrados",
  [organoleptics.APPEARENCE]: "Aspecto",
  [organoleptics.COLOR]: "Cor",
  [organoleptics.SMELL]: "Cheiro",
  [organoleptics.TASTE]: "Sabor",
  [organoleptics.TEXTURE]: "Textura",
  [organoleptics.VISCOSITY]: "Viscosidade",

  [bcIntegrations.INVENTORY_ADJUSTMENTS]: "Ajustes de inventário",
  [bcIntegrations.BATCHES]: "Batches",
  [bcIntegrations.PURCHASE_ORDERS]: "Pedidos de compra",
  [bcIntegrations.PURCHASE_ORDERS_RECEIPT]: "Recebimento de pedidos de compra",
  [bcIntegrations.TRANSFER]: "Transferências",
  [bcIntegrations.TRANSFER_RECEIPT]: "Recebimento de Transferências",
  [bcIntegrations.SALES]: "Ventas",
  [bcIntegrations.CONCILIATION]: "Conciliação",
  [bcIntegrations.PURCHASE_ORDER_MODIFY]: "Atualização do PO",
  [bcIntegrations.VENDORS]: "Fornecedores",

  [vendor.VENDOR_SUPPLY_MODULE_TITLE]: "Controle do fornecedor",
  [vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION]:
    "Gestão de fornecedores cadastrados por país.",
  [vendor.PAYMENT_DAYS]: "Dias de crédito",
  [vendor.PAYMENT_METHOD]: "Método de pagamento",
  [vendor.DOCUMENT_TYPE]: "Tipo de documento",
  [vendor.SUPPLIES_QUANTITY]: "Quantidade de suprimentos",
  [vendor.VENDORS_QUANTITY]: "Quantidade de fornecedores",
  [vendor.SHOW_SUPPLIES]: "Ver suprimentos",
  [vendor.SHOW_VENDORS]: "Ver fornecedores",
  [vendor.VENDOR_SUPPLIES_TITLE]: "Suprimentos por fornecedor",
  [vendor.SUPPLY_VENDORS_TITLE]: "Fornecedores por suprimento",
  [vendor.SUPPLY_NAME]: "Nome de entrada",
  [vendor.VENDOR_SUPPLY_NAME]: "Descrição do fornecedor",
  [vendor.VENDOR_UNIT]: "Unid. fornecedor",
  [vendor.SUPPLY_QUANTITY]: "Apresentação",
  [vendor.SUPPLY_UNIT]: "Unidade KIS",
  [vendor.VENDOR_UNIT_PRICE]: "Custo uni. forn.",
  [vendor.SUPPLY_UNIT_PRICE]: "Custo uni. KIS",
  [vendor.VENDOR_MIN_ORDER_QUANTITY]: "Quant. mín. para pedir",
  [vendor.VENDOR_ORDER_PER_WEEK]: "Pedidos por semana",
  [vendor.CATEGORY]: "Classificação",
  [vendor.VIEW_BY_VENDORS]: "Mostrar fornecedores",
  [vendor.VIEW_BY_SUPPLIES]: "Mostrar suprimentos",
  [vendor.LEAD_TIME]: "Prazo de entrega",
  [vendor.SUPPLY_VALIDATOR_LABEL_LONG]:
    "O preço unitário ({{price}}) do insumo {{sku}} (SKU) está fora do intervalo | Intervalo entre {{minPrice}} e {{maxPrice}} | O preço acordado é {{vendorPrice}}.",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT]:
    "O preço acordado é {{vendorPrice}} | Intervalo entre {{minPrice}} e {{maxPrice}}",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT_2]: "O preço acordado é {{vendorPrice}}",
  [vendor.SUPPLY_VALIDATOR_CHIP_LABEL]:
    "Existem {{number}} variaçõ(es) de preço que você deve validar.",
  [vendor.PURCHASE_EMAIL]: "E-mail de compra",
  [vendor.ACCOUNTING_EMAIL]: "E-mail contábil",

  [vendorMeasureUnit.KG]: "QUILOGRAMA",
  [vendorMeasureUnit.PZ]: "PEÇA",
  [vendorMeasureUnit.BOX]: "CAIXA",
  [vendorMeasureUnit.BAG]: "PACOTE",
  [vendorMeasureUnit.BUCKET]: "BALDE",
  [vendorMeasureUnit.CAN]: "JAR",
  [vendorMeasureUnit.GALLON]: "GALÃO",
  [vendorMeasureUnit.PACKAGE]: "PACOTE",
  [vendorMeasureUnit.DRUM]: "DRUM",
  [vendorMeasureUnit.TIN]: "TIN",
  [vendorMeasureUnit.ML]: "MILILITER",
  [vendorMeasureUnit.LITER]: "LITRO",
  [vendorMeasureUnit.BAG_SMALL]: "BAG",
  [vendorMeasureUnit.UN]: "UNIDADE",
  [vendorMeasureUnit.BED]: "BED",
  [vendorMeasureUnit.THOUSAND]: "MIL",
  [vendorMeasureUnit.G]: "G",
  [vendorMeasureUnit.ROLL]: "ROLO",
  [vendorMeasureUnit.JUG]: "JAG",
  [vendorMeasureUnit.JAR]: "JAR",
  [vendorMeasureUnit.SACK]: "CUSTO",
  [vendorMeasureUnit.BOTTLE]: "GARRAFA",
  [vendorMeasureUnit.CRATE]: "GRATE",
  [vendorMeasureUnit.DOME]: "DOME",
  [vendorMeasureUnit.COVER]: "COVER",
  [vendorMeasureUnit.TETRAPACK]: "TETRAPACK",
  [vendorMeasureUnit.ATADO]: "LIGADO",

  [commentArea.TITLE]: "Seção de comentários",
  [commentArea.SHOW_ANSWER]: "Mostrar respostas",
  [commentArea.HIDE_ANSWER]: "Ocultar respostas",
  [commentArea.COMMENT_LABEL]: "Comentário",
  [commentArea.ANSWER_LABEL]: "Resposta",

  [bulkLoadAction.CREATE]: "Inserir",
  [bulkLoadAction.MODIFY]: "Modificar",
  [bulkLoadAction.NOTHING]: "Nenhuma ação",
  [bulkLoadAction.NOTHING_BY_ERROR]: "Nenhuma ação por erro",

  [bulkLoadModule.TABLE_INFO]:
    "Antevisão da importação do registro. Confirme os dados e salve.",
  [bulkLoadModule.REQUIRED_COLUMNS]:
    "As primeiras {{num}} colunas são obrigatórias",

  [bulkLoadError.VENDOR_CODE_NOT_FOUND]: "Código do fornecedor não encontrado.",
  [bulkLoadError.PRODUCT_NOT_FOUND]: "SKU não encontrado.",
  [bulkLoadError.KITCHEN_NOT_FOUND]: "Cozinha não encontrada.",
  [bulkLoadError.VENDOR_TYPE_NOT_FOUND]: "Tipo não encontrado.",
  [bulkLoadError.ALL_FIELDS_MUST_BE_FILLED_TO_INSERT]:
    "Para inserir, todos os campos devem ser preenchidos.",
  [bulkLoadError.UNIDENTIFIED]: "Erro não identificado.",
  [bulkLoadError.VENDOR_UNIT_NOT_FOUND]:
    "Unidade do fornecedor não encontrada.",
};
