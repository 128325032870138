import { KOSSelectedResult } from "@foodology-co/alejandria";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import PurchaseOrderStatusSelector from "app/components/PurchaseOrder/Status/Selector";
import PurchaseOrderUserSelector from "app/components/PurchaseOrder/UserSelector";
import PurchaseTable from "app/components/PurchaseRecord/PurchaseTable";
import VendorSelector from "app/components/Vendor/Selector";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { getCityName } from "app/utils/city.utils";
import { DownloadZipPayload } from "core/attachment/entity";
import {
  downloadPurchaseOrdersZip,
  getPurchaseOrderPdfName,
} from "core/attachment/repository/http";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  getByIds,
  getPurchaseOrders,
} from "core/purchaseOrders/repositories/http/purchase";
import { Vendor } from "core/vendors/entities/Vendor";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText, getLocationAndType } from "utils/general";
import { PageBox } from "utils/generalUI";
import { TypeOfRole as Role } from "utils/role";

interface Props {}

const PurchaseOrder: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.session.user.data);
  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const [orders, setOrders] = useState<Purchase[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Purchase[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [userIdFilter, setUserIdFilter] = useState<string>();
  const [cleanSearch, setCleanSearch] = useState<number>(0);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [loadingZip, setLoadingZip] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getData = () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedKitchen,
    });
    if (!params) return;
    setLoading(true);
    setSelectedStatus(undefined);
    setSelectedVendor(undefined);
    setSearch("");
    setUserIdFilter("");
    setDateFilter("");
    setCleanSearch(new Date().getTime());
    setOrders([]);
    getPurchaseOrders(params.type, params.location)
      .then((response) => {
        setOrders(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [selectedCountry, selectedKitchen]);

  useEffect(() => {
    const statusesDataSet = new Set(orders.map((order) => order.status));
    const statusesArray = [...statusesDataSet];
    setStatuses(statusesArray);
  }, [orders]);

  const searchValidator = (order: Purchase, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(order.orderId, toSearch) ||
      compareSearchText(order.code, toSearch) ||
      compareSearchText(order.vendorName, toSearch)
    );
  };

  const statusValidator = (order: Purchase, status: string): boolean => {
    if (!status) return true;
    return order.status === status;
  };

  const dateValidator = (order: Purchase, date: string): boolean => {
    if (!date) return true;
    return order.dateDocument === date;
  };

  const userIdValidator = (order: Purchase, userId: string): boolean => {
    if (!userId) return true;
    return order.userId === userId;
  };

  const vendorValidate = (order: Purchase): boolean => {
    const vendorId = selectedVendor?.id ? selectedVendor.id.toString() : "";
    if (!vendorId) return true;
    return order.vendorId === vendorId;
  };

  useEffect(() => {
    setFilteredOrders(
      orders.filter((order) => {
        return (
          searchValidator(order, search) &&
          statusValidator(order, selectedStatus ?? "") &&
          dateValidator(order, dateFilter) &&
          userIdValidator(order, userIdFilter ?? "") &&
          vendorValidate(order)
        );
      })
    );
  }, [
    orders,
    search,
    selectedStatus,
    dateFilter,
    userIdFilter,
    selectedVendor,
  ]);

  const reportDetailed = (purchaseOrders: Purchase[]) => {
    return purchaseOrders
      .map((purchase: Purchase) => {
        return purchase.lines?.map((line) => {
          const vendorQuantity = line.supplyVendorQuantity ?? 1;
          return {
            ciudad: getCityFromKitchen(purchase.kitchenId),
            Cocina: purchase.kitchenId,
            OC: purchase.code,
            SKU: line.sku,
            Proveedor: purchase.vendorName,
            "Código Proveedor": purchase.vendorId,
            Presentación: vendorQuantity,
            "Cantidad Proveedor": line.quantity / vendorQuantity,
            "Producto Proveedor": line.supplyVendorName ?? line.description,
            "Unidad Medida Proveedor": line.supplyVendorUnit ?? line.unit,
            "Costo Unitario": parseFloat(line.unitCost.toFixed(2)),
            total: parseFloat((line.quantity * line.unitCost).toFixed(2)),
            "Fecha Creación": purchase.dateDocument,
            "Fecha Entrega": purchase.expectedReceiptAt,
          };
        });
      })
      .flat();
  };

  const getDetailedCsv = () => {
    const ids = selectedItems.map((item) => item.orderId).join(",");
    getByIds(ids)
      .then((response) => {
        const data = reportDetailed(response);
        downloadTextAsCsv(
          json2Csv(data),
          `${t(purchaseOrderModule.DOWNLOAD_LINES)} ${new Date().getTime()}.csv`
        );
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const getCSV = () => {
    if (orders.length) {
      const ids = selectedItems.map((item) => item.orderId);
      const data = orders
        .filter((order) => ids.includes(order.orderId))
        .map((purchase) => {
          return {
            code: purchase.code,
            kitchen: purchase.kitchenId,
            proveedor: purchase.vendorName,
            date: purchase.dateDocument,
            estimated: purchase.expectedReceiptAt,
            status: purchase.status,
            user: purchase.userName,
          };
        });
      downloadTextAsCsv(
        json2Csv(data),
        `${t(purchaseOrderModule.DOWNLOAD_OCS)} ${new Date().getTime()}.csv`
      );
      handleClose();
    }
  };

  const getCityFromKitchen = (kitchenId: string) => {
    const kitchen = kitchens.find((k) => k.kitchenId === kitchenId);
    return getCityName(kitchen?.city as string);
  };

  const onUpdateItem = useCallback(
    (id: string, purchase: Purchase) => {
      setOrders((prev) =>
        prev.map((el) => (el.orderId === id ? purchase : el))
      );
    },
    [orders]
  );

  const downloadPdfs = useCallback(() => {
    setLoadingZip(true);
    const data = selectedItems.map((item) => {
      const { country, orderId, code, kitchenId, vendorName } = item;
      return {
        id: orderId,
        country,
        name: getPurchaseOrderPdfName(code, kitchenId, vendorName),
      } as DownloadZipPayload;
    });
    downloadPurchaseOrdersZip(data).finally(() => {
      setLoadingZip(false);
    });
  }, [selectedItems]);

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderModule.TITLE)}
        subtitle={t(purchaseOrderModule.DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
          {
            children: t(commons.EXPORT),
            variant: "outlined",
            color: "info",
            onClick: handleClick,
            id: "update-purchase-order",
            disabled: !selectedItems.length,
            startIcon: <FileDownloadOutlinedIcon />,
          },
          {
            children: t(commons.DOWNLOAD_PDFS),
            variant: "outlined",
            color: "error",
            startIcon: (
              <Box>
                {loadingZip ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <SimCardDownloadOutlinedIcon />
                )}
              </Box>
            ),
            onClick: downloadPdfs,
            disabled: !selectedItems.length || loadingZip,
          },
        ]}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={getCSV}>
          {t(purchaseOrderModule.DOWNLOAD_OCS)}
        </MenuItem>
        <MenuItem onClick={getDetailedCsv}>
          {t(purchaseOrderModule.DOWNLOAD_LINES)}
        </MenuItem>
      </Menu>

      <SearchOnTopBar onSearch={setSearch} clean={cleanSearch} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={12} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(purchaseOrderModule.FILTER_ORDERS)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <KitchenSelector
                  onCountryChange={(country) =>
                    setSelectedCountry(country ?? "")
                  }
                  onKitchenChange={(kitchen) =>
                    setSelectedKitchen(kitchen?.kitchenId ?? "")
                  }
                  hideCitySelector
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  type="date"
                  value={dateFilter}
                  onChange={(e) => {
                    setDateFilter(e.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setDateFilter("")}>
                        <CancelOutlinedIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={!selectedCountry}
                />
              </Grid>
              <Grid item sm={2}>
                <PurchaseOrderStatusSelector
                  options={statuses}
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                  disabled={!selectedCountry}
                />
              </Grid>
              <Grid item sm={2}>
                <VendorSelector
                  country={selectedCountry ?? ""}
                  selectedVendor={selectedVendor}
                  setSelectedVender={setSelectedVendor}
                  disabled={!selectedCountry}
                  isShortTitle
                />
              </Grid>
              <Grid item sm={2}>
                <PurchaseOrderUserSelector
                  orders={orders}
                  selected={userIdFilter}
                  setSelected={setUserIdFilter}
                  currentUserId={
                    user?.role === Role.COMPRAS ? user?._id : undefined
                  }
                  disabled={!selectedCountry}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <PurchaseTable
        items={filteredOrders}
        loading={loading}
        onUpdateItem={onUpdateItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        canPagination={dateFilter === ""}
      />
    </PageBox>
  );
};

export default PurchaseOrder;
