import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, Grid } from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons } from "app/i18n/types";
import {
  ExternalInvoice,
  PurchaseInvoice,
} from "core/purchaseOrders/entities/Invoice";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { linkInvoice } from "core/purchaseOrders/repositories/http/purchase";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";

interface Props {
  purchases: Purchase[];
  invoice: PurchaseInvoice;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}
export const PurchaseOrderInvoiceLinkList = (props: Props) => {
  const { t } = useTranslation();

  const { purchases, open, onClose, invoice, onUpdate } = props;
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const closeList = () => {
    setSelectedPurchase(null);
    onClose();
  };

  const getPurchasesHeader = useCallback((): KOSBaseTableHeader[] => {
    const actionButton = (item: KOSRowData) => {
      return (
        <Checkbox
          onClick={() => {
            if (selectedPurchase && item.code === selectedPurchase.code) {
              setSelectedPurchase(null);
            } else {
              setSelectedPurchase(item as Purchase);
            }
          }}
          checked={
            (selectedPurchase && item.code === selectedPurchase.code) ?? false
          }
        />
      );
    };

    return [
      {
        label: t(commons.CODE),
        text: (item: KOSRowData) => item.code,
      },
      {
        label: t(commons.KITCHEN),
        text: (item: KOSRowData) => item.kitchenId,
      },
      {
        label: t(commons.DATE),
        text: (item: KOSRowData) => item.dateDocument,
      },
      {
        label: t(commons.INVOICE),
        text: (item: KOSRowData) => item.invoiceNo,
      },
      {
        label: t(commons.SUBTOTAL),
        text: (item: KOSRowData) =>
          formatPrice(item.totalNet, localeData[item.country]),
      },
      {
        label: t(commons.ACTIONS),
        align: "center",
        component: actionButton,
      },
    ];
  }, [selectedPurchase]);

  const saveLink = () => {
    if (selectedPurchase) {
      setLoading(true);
      const externalInvoice = {
        legalDocumentNo: invoice.id,
        fileName: invoice.pdf,
        url: invoice.pdfUrl,
        invoice: invoice.invoice,
        issueDate: invoice.issueDate.toString(),
        subtotal: invoice.subtotal,
        tax: invoice.tax,
        country: invoice.country,
        items: invoice.items,
      } as ExternalInvoice;
      linkInvoice(selectedPurchase.orderId, externalInvoice)
        .then(() => {
          onClose();
          onUpdate();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Box>
      <ManagerDialog
        title={purchases[0]?.vendorName ?? ""}
        open={open}
        onClose={closeList}
        size="lg"
        content={
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <KOSBaseTable
                columns={getPurchasesHeader()}
                rows={{
                  data: purchases ?? [],
                  total: purchases?.length ?? 0,
                  loading: false,
                }}
                pagination={{ enable: true }}
                emptyState={
                  <KOSEmptyState
                    icon={InfoOutlinedIcon}
                    message={t(commons.TABLE_EMPTY)}
                  />
                }
              />
            </Grid>
          </Grid>
        }
        actions={
          <ManagerDialogFooter
            onCancel={onClose}
            mainButton={{
              children: t(commons.LINK),
              onClick: () => saveLink(),
              disabled: loading || !selectedPurchase,
            }}
            loading={loading}
          />
        }
      />
    </Box>
  );
};
